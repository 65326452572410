import React from 'react';

const PreviewStep = ({ formData }) => {
  const renderSection = (title, content) => (
    <div className="mb-8">
      <h3 className="text-2xl font-semibold mb-6 text-indigo-800 border-b pb-2">{title}</h3>
      {content}
    </div>
  );

  const renderKeyValue = (label, value) => (
    <div className="flex flex-col sm:flex-row sm:items-center mb-4">
      <div className="w-full sm:w-1/3 font-medium text-gray-700">{label}</div>
      <div className="w-full sm:w-2/3 text-gray-900 mt-1 sm:mt-0">{value || <span className="text-gray-500">N/A</span>}</div>
    </div>
  );

  return (
    <div className="max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-lg">
      {/* Basic Details */}
      {renderSection("Basic Details", (
        <div>
          {renderKeyValue("Job Title", formData.title)}
          {renderKeyValue("Number of Hires", formData.number_of_hires)}
          {renderKeyValue("Location", formData.addressgeo)}
          {renderKeyValue("Postcode", formData.postcode)}
          {renderKeyValue("City", formData.city)}
          {renderKeyValue("Region", formData.region)}
          {renderKeyValue("Industry", formData.industry)}
          {renderKeyValue("Job Reference", formData.job_refreneces)}
        </div>
      ))}

      {/* Terms */}
      {renderSection("Terms & Conditions", (
        <div>
          {renderKeyValue("Job Type", formData.job_type)}
          {renderKeyValue("Graduate Job", formData.is_graduate_job ? 'Yes' : 'No')}
          {renderKeyValue("Start Date", formData.start_asap ? 'ASAP' : formData.start_date || 'N/A')}
          {renderKeyValue("Salary", 
            formData.show_pay_by === 'Range' 
              ? `£${formData.salary_min} - £${formData.salary_max} per ${formData.salarytype}`
              : `£${formData.salary} per ${formData.salarytype}`
          )}
          {renderKeyValue("Working Hours", `${formData.hours} hours (${formData.hours_from} - ${formData.hours_till})`)}
          {renderKeyValue("Break Duration", `${formData.break_duration_min} minutes (${formData.is_break_paid ? 'Paid' : 'Unpaid'})`)}
        </div>
      ))}

      {/* Requirements */}
      {renderSection("Requirements", (
        <div>
          {renderKeyValue("Required Qualifications", formData.qualifications.join(', '))}
          {renderKeyValue("Preferred Qualifications", formData.preferred_qualifications.join(', '))}
          {renderKeyValue("Minimum Experience", `${formData.min_year_exp} years`)}
          {renderKeyValue("Education Level", formData.education)}
          {renderKeyValue("Remote Work", formData.remotetype)}
          {renderKeyValue("CV Required", formData.cv_required ? 'Yes' : 'No')}
          {renderKeyValue("References Required", formData.is_references_required ? formData.references_required_options : 'No')}
        </div>
      ))}

      {/* Job Description */}
      {renderSection("Job Description", (
        <div className="prose max-w-none text-gray-800">
          {formData.description.split('\n').map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}
        </div>
      ))}

      {/* About Company */}
      {renderSection("About Company", (
        <div>
          {renderKeyValue("Company Name", formData.company)}
          {renderKeyValue("Company Email", formData.company_email)}
          {renderKeyValue("Phone", formData.phone)}
          {renderKeyValue("Website", formData.company_website)}
          {renderKeyValue("Slogan", formData.company_slogan)}
          <div className="mt-4">
            <h4 className="font-medium text-gray-700 mb-2">Company Overview</h4>
            <p className="text-gray-900">{formData.company_overview_text || <span className="text-gray-500">N/A</span>}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PreviewStep;
