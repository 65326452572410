import React, { useState, useEffect } from "react";
import JobDataService from "../services/JobDataService";

const BasicDetailsStep = ({ formData, onChange }) => {
  const [industries, setIndustries] = useState([]);
  const [genericData, setGenericData] = useState([]);
  const [isLoadingIndustries, setIsLoadingIndustries] = useState(true);

  useEffect(() => {
    const loadIndustries = async () => {
      try {
        const data = await JobDataService.getIndustries();
        const g_data = JobDataService.getGenericData();
        setIndustries(data);
        setGenericData(g_data);
      } catch (error) {
        console.error("Error loading industries:", error);
      } finally {
        setIsLoadingIndustries(false);
      }
    };

    loadIndustries();
  }, []);

  return (
    <div className="flex flex-col gap-4 max-w-[456px]">
      {/* Number of Hires */}
      <div className="max-w-[200px]">
        <label className="block text-gray-700 text-sm font-medium mb-2">
          Number of Hires <span className="text-red-500 font-bold">*</span>
        </label>
        <input
          type="number"
          className="w-[88px] px-3 py-2 border border-gray-300 rounded-md"
          value={formData.number_of_hires}
          onChange={(e) => onChange("number_of_hires", e.target.value)}
        />
      </div>

      {/* Job Title */}
      <div>
        <label className="block text-gray-700 text-sm font-medium mb-2">
          Job Title <span className="text-red-500 font-bold">*</span>
        </label>
        <input
          type="text"
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
          value={formData.title}
          onChange={(e) => onChange("title", e.target.value)}
        />
      </div>

      {/* Location and Postcode */}
      <div className="flex gap-4">
        <div className="flex-1">
          <label className="block text-gray-700 text-sm font-medium mb-2">
            Location <span className="text-red-500 font-bold">*</span>
          </label>
          <input
            type="text"
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
            value={formData.addressgeo}
            onChange={(e) => onChange("addressgeo", e.target.value)}
          />
        </div>
        <div className="w-[90px]">
          <label className="block text-gray-700 text-sm font-medium mb-2">
            Postcode <span className="text-red-500 font-bold">*</span>
          </label>
          <input
            type="text"
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
            value={formData.postcode}
            onChange={(e) => onChange("postcode", e.target.value)}
          />
        </div>
      </div>

      {/* City and Region */}
      <div className="flex gap-4">
        <div className="flex-1">
          <label className="block text-gray-700 text-sm font-medium mb-2">
            City<span className="text-red-500 font-bold">*</span>
          </label>
          <input
            type="text"
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
            value={formData.city}
            onChange={(e) => onChange("city", e.target.value)}
          />
        </div>
        <div className="flex-1">
          <label className="block text-gray-700 text-sm font-medium mb-2">
            Region
          </label>
          <input
            type="text"
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
            value={formData.region}
            onChange={(e) => onChange("region", e.target.value)}
          />
        </div>
      </div>

      {/* Generic Job Details */}
      <div>
        <label className="block text-gray-700 text-sm font-medium mb-2">
          Generic Job Details
        </label>
        <select
          className={`w-full px-3 py-2 border border-gray-300 rounded-md appearance-none bg-white`}
          value={formData.generic_job_detail}
          onChange={(e) => onChange("generic_job_detail", e.target.value)}
        >
          <option value="">Select Detail</option>
          {genericData.map((data, key) => (
            <option key={key} value={data}>
              {data}
            </option>
          ))}
        </select>
      </div>

      {/* Industry Dropdown */}
      <div>
        <label className="block text-gray-700 text-sm font-medium mb-2">
          Industry <span className="text-red-500 font-bold">*</span>
        </label>
        <div className="relative">
          <select
            className={`w-full px-3 py-2 border border-gray-300 rounded-md appearance-none bg-white
              ${isLoadingIndustries ? "bg-gray-50" : ""}`}
            value={formData.industry.toLowerCase()}
            onChange={(e) => onChange("industry", e.target.value)}
            disabled={isLoadingIndustries}
          >
            <option value="">Select Industry</option>
            {industries.map((industry) => (
              <option key={industry.id} value={industry.id.toLowerCase()}>
                {industry.name}
              </option>
            ))}
          </select>
          {isLoadingIndustries && (
            <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
              <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-indigo-500"></div>
            </div>
          )}
        </div>
        {formData.industry && (
          <p className="mt-1 text-sm text-gray-500">
            {industries.find((i) => i.id === formData.industry)?.description}
          </p>
        )}
      </div>

      {/* Job Reference */}
      <div>
        <label className="block text-gray-700 text-sm font-medium mb-2">
          Your Job Reference
        </label>
        <input
          type="text"
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
          value={formData.job_refreneces}
          onChange={(e) => onChange("job_refreneces", e.target.value)}
        />
      </div>
    </div>
  );
};

export default BasicDetailsStep;
