import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { API_BASE_URL } from './utils/api';
import { Edit, Trash, CheckCircle, FileText, MoreVertical, XCircle, Users, Layers, ChevronDown, ChevronUp } from 'lucide-react';

// Helper function to parse date in DD/MM/YYYY format (unchanged)
const parseDate = (dateString) => {
  if (!dateString) return new Date(0);
  const [day, month, year] = dateString.split('/');
  return new Date(`${year}-${month}-${day}`);
};

// Fetch jobs function (unchanged)
const fetchJobs = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}admin/jobs/api`);
      console.log("response.data: ", response.data);      
      return response.data;
    } catch (error) {
      console.error("Error fetching jobs from API:", error);
      throw error;
    }
};

const ActionMenu = ({ jobId, onAction, isCompleted, expandRow }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleAction = (action) => {
    onAction(action, jobId);
    setIsOpen(false);
  };

  const actions = [
    { name: 'Delete', icon: <Trash size={16} className="mr-2" /> },
    { name: 'Edit', icon: <Edit size={16} className="mr-2" /> },
    { name: 'Generate Questions', icon: <FileText size={16} className="mr-2" /> },
    { 
      name: isCompleted ? 'Mark as Not Completed' : 'Mark as Completed', 
      icon: isCompleted ? <XCircle size={16} className="mr-2" /> : <CheckCircle size={16} className="mr-2" /> 
    },
    { name: 'View Jobseekers', icon: <Users size={16} className="mr-2" /> },
    { name: 'View Swim Lanes', icon: <Layers size={16} className="mr-2" /> },
  ];

  // Sort actions alphabetically by name
  const sortedActions = actions.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div className="relative" ref={menuRef}>
      <button
        onClick={() => {
          setIsOpen(!isOpen);
          expandRow(jobId);
        }}
        className="p-2 rounded-full hover:bg-gray-200 focus:outline-none z-40 relative"
      >
        <MoreVertical size={24} strokeWidth={2.5} />
      </button>
      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-50">
          <div className="py-1">
            {sortedActions.map(({ name, icon }) => (
              <button
                key={name}
                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                onClick={() => handleAction(name)}
              >
                {icon} {name}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const JobListing = ({ onJobSelected, onViewJobseekers, onViewSwimLanes, companyJobs}) => {
  const [jobs, setJobs] = useState([companyJobs]);
  const [expandedJob, setExpandedJob] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchAndSetJobs = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const data = jobs;
      console.log("jobs::::", data);
      const newStructureJobs = data.filter(job => job.jobAdvert);
      setJobs(newStructureJobs.sort((a, b) => 
        parseDate(b.jobAdvert.jobDetails.startDate.date) - parseDate(a.jobAdvert.jobDetails.startDate.date)
      ));
    } catch (err) {
      console.error("Failed to fetch jobs:", err);
      setError("Failed to load jobs. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAndSetJobs();
  }, []);

  const handleAction = async (action, jobId) => {
    console.log(`Performing action: ${action} on job:`, jobId);
    switch (action) {
      case 'Generate Questions':
        if (typeof onJobSelected === 'function') {
          onJobSelected(jobId);
        } else {
          console.error('onJobSelected is not a function');
        }
        break;
      case 'View Jobseekers':
        if (typeof onViewJobseekers === 'function') {
          onViewJobseekers(jobId);
        } else {
          console.error('onViewJobseekers is not a function');
        }
        break;
      case 'View Swim Lanes':
        if (typeof onViewSwimLanes === 'function') {
          onViewSwimLanes(jobId);
        } else {
          console.error('onViewSwimLanes is not a function');
        }
        break;
      case 'Delete':
        if (window.confirm('Are you sure you want to delete this job?')) {
          try {
	        await axios.delete(`${API_BASE_URL}admin/jobs/api/${jobId}`);
            await fetchAndSetJobs();
          } catch (error) {
            console.error("Error deleting job:", error);
          }
        }
        break;
      case 'Mark as Completed':
      case 'Mark as Not Completed':
        try {
	      await axios.patch(`${API_BASE_URL}admin/jobs/api/${jobId}/toggle-completion`);
          await fetchAndSetJobs();
        } catch (error) {
          console.error("Error toggling job completion:", error);
        }
        break;
      default:
        console.log(`Action ${action} not implemented yet.`);
    }
  };
  
  const getJobDetails = (job) => {
    const { basicInfo, jobDetails, workingConditions, qualifications, description } = job.jobAdvert || {};
    return {      
      id: job._id || 'N/A',
      completed: job.completed || false,
      title: basicInfo?.jobTitle || job.jobReference || 'N/A',
      jobReference: job.jobReference || basicInfo?.jobReference || 'N/A',
      company: job.company?.name || 'N/A',
      location: basicInfo?.location?.details ? `${basicInfo.location.details.city}, ${basicInfo.location.details.country}` : 'N/A',
      postedDate: job.createdAt || jobDetails?.startDate?.date || 'N/A',
      salary: jobDetails?.salary?.exactAmount 
        ? `${jobDetails.salary.exactAmount.currency?? 'Pound Sterling'} ${jobDetails.salary.exactAmount.amount} ${jobDetails.salary.exactAmount.frequency.value}`
        : 'N/A',
      type: jobDetails?.jobType?.value || 'N/A',
      startDate: jobDetails?.startDate?.date || 'N/A',
      contractLength: jobDetails?.duration ? `${jobDetails.duration.value} ${jobDetails.duration.unit.value}` : 'N/A',
      numberOfHires: basicInfo?.numberOfHires?.toString() || 'N/A',
      industry: job.company?.industry || basicInfo?.industry || 'N/A',
      workingHours: workingConditions?.hours?.defined 
        ? `${workingConditions.hours.defined.from} - ${workingConditions.hours.defined.to}`
        : 'N/A',
      breaks: workingConditions?.breaks
        ? `${workingConditions.breaks.duration.value} ${workingConditions.breaks.paid ? '(Paid)' : '(Unpaid)'}`
        : 'N/A',
      weekendWork: workingConditions?.weekendWork?.value || 'None',
      weekendPay: workingConditions?.weekendWork?.pay?.value || 'N/A',
      qualifications: {
        required: (qualifications?.required || []).join(', '),
        preferred: (qualifications?.preferred || []).join(', '),
        additionalRequirements: (qualifications?.additionalRequirements || []).join(', '),
        preferredSkills: (qualifications?.preferredSkills || []).join(', '),
        experience: qualifications?.experience?.minimumYears?.value?.toString() || 'N/A',
        education: qualifications?.education || 'Not specified',
      },
      remoteType: qualifications?.remoteType?.value || 'N/A',
      description: description || 'No description available',
    };
  };

  const expandRow = (jobId) => {
    setExpandedJob(expandedJob === jobId ? null : jobId);
  };

  if (isLoading) {
    return <div className="text-center py-4">Loading jobs...</div>;
  }

  if (error) {
    return <div className="text-center py-4 text-red-500">{error}</div>;
  }

  const renderJobDetails = (jobDetails) => (
    <div className="mt-4 space-y-4 bg-gray-50 p-4 rounded-lg shadow-inner">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <h4 className="font-semibold mb-2 text-indigo-700">Basic Information</h4>
          <div className="space-y-1">
            <p><strong>Industry:</strong> {jobDetails.industry}</p>
            <p><strong>Number of Hires:</strong> {jobDetails.numberOfHires}</p>
            <p><strong>Type:</strong> {jobDetails.type}</p>
            <p><strong>Start Date:</strong> {jobDetails.startDate}</p>
            <p><strong>Contract Length:</strong> {jobDetails.contractLength}</p>
            <p><strong>Salary:</strong> {jobDetails.salary}</p>
          </div>
        </div>
        <div>
          <h4 className="font-semibold mb-2 text-indigo-700">Working Conditions</h4>
          <div className="space-y-1">
            <p><strong>Working Hours:</strong> {jobDetails.workingHours}</p>
            <p><strong>Breaks:</strong> {jobDetails.breaks}</p>
            <p><strong>Weekend Work:</strong> {jobDetails.weekendWork}</p>
            <p><strong>Weekend Pay:</strong> {jobDetails.weekendPay}</p>
            <p><strong>Remote Type:</strong> {jobDetails.remoteType}</p>
          </div>
        </div>
      </div>
      <div>
        <h4 className="font-semibold mb-2 text-indigo-700">Qualifications</h4>
        <div className="space-y-1">
          <p><strong>Required:</strong> {jobDetails.qualifications.required}</p>
          <p><strong>Preferred:</strong> {jobDetails.qualifications.preferred}</p>
          <p><strong>Additional Requirements:</strong> {jobDetails.qualifications.additionalRequirements}</p>
          <p><strong>Preferred Skills:</strong> {jobDetails.qualifications.preferredSkills}</p>
          <p><strong>Experience:</strong> {jobDetails.qualifications.experience} years</p>
          <p><strong>Education:</strong> {jobDetails.qualifications.education}</p>
        </div>
      </div>
      <div>
        <h4 className="font-semibold mb-2 text-indigo-700">Job Description</h4>
        <p className="whitespace-pre-wrap">{jobDetails.description}</p>
      </div>
    </div>
  );

  return (
    <div className="container mx-auto p-4 lg:p-8">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-3xl font-bold text-indigo-800">Job Listings</h2>
        <button className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition duration-300 ease-in-out transform hover:scale-105">
          Create Job
        </button>
      </div>
      {jobs.length === 0 ? (
        <div className="text-center py-8 text-gray-500 text-xl">No jobs found.</div>
      ) : (
        <div className="bg-white shadow-lg rounded-lg overflow-hidden">
          {jobs.map((job) => {
            const jobDetails = getJobDetails(job);
            return (
              <div key={jobDetails.id} className={`border-b border-gray-200 last:border-b-0 ${jobDetails.completed === true ? 'bg-green-50' : ''}`}>
                <div className="flex items-center p-6 hover:bg-gray-50 transition duration-150 ease-in-out">
                  <div 
                    className="flex-grow cursor-pointer"
                    onClick={() => expandRow(jobDetails.id)}
                  >
                    <h3 className="text-xl font-semibold text-indigo-700">
                      {jobDetails.title} 
                      <span className="text-sm text-gray-500 ml-2">({jobDetails.jobReference})</span>
                    </h3>
                    <p className="text-md text-gray-600">{jobDetails.company} • {jobDetails.location}</p>
                    <p className="text-sm text-gray-500">Posted on {new Date(jobDetails.postedDate).toLocaleDateString()}</p>
                    <div className="flex items-center mt-2">
                      <span className="px-2 py-1 rounded-full text-xs bg-blue-100 text-blue-800 mr-2">{jobDetails.type}</span>
                      <span className="px-2 py-1 rounded-full text-xs bg-green-100 text-green-800">{jobDetails.salary}</span>
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    {expandedJob === jobDetails.id ? (
                      <ChevronUp size={20} className="text-gray-400" />
                    ) : (
                      <ChevronDown size={20} className="text-gray-400" />
                    )}
                    <ActionMenu 
                      jobId={jobDetails.id} 
                      onAction={handleAction} 
                      isCompleted={jobDetails.completed === true} 
                      expandRow={expandRow}
                    />
                  </div>
                </div>
                {expandedJob === jobDetails.id && renderJobDetails(jobDetails)}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default JobListing;
