import React, { useState, useEffect, useCallback } from "react";
import QuestionnaireBuilder from "./QuestionnaireBuilder";
import ChatInterface from "./ChatInterface";
import AuthScreen from "./AuthScreen";
import Header from "./Header";
import JobseekerList from "./JobseekerList";
import JobListing from "./JobListing";
import SwimLanes from "./SwimLanes";
import Dashboard from "./Dashboard";
import Billing from "./Billing";
import CompanySettings from "./CompanySettings";
import { NotificationProvider } from "./CustomNotificationSystem";
import axios from "axios";
import { API_BASE_URL } from "./utils/api";
import { loadJobsJobseekers } from "./utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ForgotPasswordPage from "./ForgotPassword";
import ResetPasswordPage from "./ResetPassword";
import JobPostForm from './JobPostForm';
import WorkerManagement from "./WorkerManagement";

// Create an axios instance with default headers
const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
    Accept: "application/json",
  },
});

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentView, setCurrentView] = useState("dashboard");
  const [selectedJob, setSelectedJob] = useState("");
  const [jobs, setJobs] = useState([]);
  const [jobseekers, setJobseekers] = useState([]);
  const [expandedJobseekerId, setExpandedJobseekerId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [isLoadingInitialData, setIsLoadingInitialData] = useState(false);
  const [company, setCompany] = useState({});
  const [userToken, setUserToken] = useState(null);
  const [pageMode, setPageMode] = useState(null);
  const [pageFor, setPageFor] = useState(null);

  const handleLogout = useCallback(() => {
    localStorage.removeItem("token");
    setIsLoggedIn(false);
    setUser(null);
    setJobs([]);
    setJobseekers([]);
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get("token");
    const mode = searchParams.get("mode");
    const page_for = searchParams.get("for"); // page is for jobseeker or company
    if (token) {
      setUserToken(token);
    }
    if (mode) {
      setPageMode(mode);
    }
    if (page_for) {
      setPageFor(page_for);
    }
  });

  const fetchJobs = useCallback(async (companyId) => {
    try {
      if (!companyId) {
        console.error("Company ID is undefined");
        return [];
      }
      const token = localStorage.getItem("token");
      const response = await api.get(`/admin/jobs/api/company/${companyId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log("Fetched jobs:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching jobs from API:", error);
      throw error;
    }
  }, []);

  const fetchJobseekersForJob = useCallback(async (jobId) => {
    try {
      const data = await loadJobsJobseekers(jobId);
      setJobseekers(data);
    } catch (error) {
      console.error(`Error fetching jobseekers for job ${jobId}:`, error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchInitialData = useCallback(
    async (userData) => {
      setIsLoadingInitialData(true);
      try {
        if (!userData || !userData.company) {
          console.error("No company ID found in user data:", userData);
          return;
        }
        const jobsData = await fetchJobs(userData.company);
        setJobs(jobsData);
        if (jobsData.length > 0) {
          const firstJobId = jobsData[0]._id;
          setSelectedJob(firstJobId);
          await fetchJobseekersForJob(firstJobId);
        }
      } catch (error) {
        console.error("Error fetching initial data:", error);
      } finally {
        setIsLoadingInitialData(false);
      }
    },
    [fetchJobs, fetchJobseekersForJob],
  );

  const handleLogin = async (email, password) => {
    try {
      const response = await api.post("/api/auth/login", {
        email,
        password,
      });
      localStorage.setItem("token", response.data.token);
      setIsLoggedIn(true);
      setUser(response.data.user);
      await fetchInitialData(response.data.user);
      window.location.href = '.'; // reloading page once, so currentview gets set for the dahsboard
    } catch (error) {
      alert(error.response.data.message);
      //toast(error.response.data.message);
    }
  };

  const checkAuthStatus = useCallback(async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      if (token) {
        const response = await api.get("/api/auth/check", {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.data.isAuthenticated) {
          setIsLoggedIn(true);
          setUser(response.data.user);
          await fetchInitialData(response.data.user);
        } else {
          handleLogout();
        }
      } else {
        handleLogout();
      }
    } catch (error) {
      console.error("Error checking auth status:", error);
      if (error.response && error.response.status === 401) {
        handleLogout();
      }
    } finally {
      setIsLoading(false);
    }
  }, [fetchInitialData, handleLogout]);

  const handleCompanySave = async (updatedCompany) => {
    // Handle saving the updated company data
    // This might involve an API call to your backend
    const token = localStorage.getItem("token");
    if (token) {
      const response = await api.put(`/api/companies/${company._id}`, updatedCompany, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200)
        setCompany(updatedCompany);
    };
  }

    useEffect(() => {
      checkAuthStatus();
    }, [checkAuthStatus]);

    useEffect(() => {
      if (selectedJob) {
        fetchJobseekersForJob(selectedJob);
      }
    }, [selectedJob, fetchJobseekersForJob]);

    useEffect(() => {
      const loadCompany = async () => {
        const token = localStorage.getItem("token");
        if (token) {
          const response = await api.get("/api/companies/current", {
            headers: { Authorization: `Bearer ${token}` },
          });

          if (response.status === 200) {
            setCompany(response.data);
          }
        }
      };
      if (currentView === "companysettings" || currentView === "dashboard")
        loadCompany();
    }, [currentView]);

    const handleJobChange = useCallback(
      (e) => {
        const newJobId = e.target.value;
        setSelectedJob(newJobId);
        fetchJobseekersForJob(newJobId);
      },
      [fetchJobseekersForJob],
    );

    const handleViewSwimLanes = useCallback((jobId) => {
      console.log("View swim lanes for job:", jobId);
      setSelectedJob(jobId);
      setCurrentView("swimLanes");
    }, []);

    const handleMenuItemClick = useCallback((view) => {
      setCurrentView(view);
    }, []);

    const handleNavigate = useCallback((view, jobseekerId = null) => {
      setCurrentView(view);
      if (view === "jobseekerList" && jobseekerId) {
        setExpandedJobseekerId(jobseekerId);
      } else {
        setExpandedJobseekerId(null);
      }
    }, []);

    if (isLoading) {
      return <div>Loading...</div>;
    }

    if (!isLoggedIn) {
      let content;
      if (currentView === "forgotpassword") {
        content = <ForgotPasswordPage changeView={setCurrentView} />;
      } else if (pageMode === "reset-password") {
        content = <ResetPasswordPage token={userToken} />;
      } else if (pageMode === "chat") {
        content = <ChatInterface selectedJob={null} userType={pageFor} />;
      } else {
        content = (
          <AuthScreen
            onLogin={handleLogin}
            changeToForgotPassword={setCurrentView}
          />
        );
      }
      return content;
    }

    if (isLoadingInitialData) {
      return <div>Loading dashboard data...</div>;
    }

    return (
      <>
        <NotificationProvider>
          <div className="App bg-gray-100 min-h-screen">
            <Header
              selectedJob={selectedJob}
              onJobChange={handleJobChange}
              jobs={jobs}
              onMenuItemClick={handleMenuItemClick}
              currentView={currentView}
              onLogout={handleLogout}
              user={user}
            />
            <div>
              {currentView === "dashboard" && (
                <Dashboard
                  jobs={jobs}
                  jobseekers={jobseekers}
                  onNavigate={handleNavigate}
                />
              )}
              {currentView === "createJob" && (
                <div className="tw-p-6">
                  <JobPostForm
                    onSubmit={async (formData) => {
                      try {
                        const token = localStorage.getItem("token");
                        const response = await api.post(
                          "/admin/jobs/api",
                          formData,
                          {
                            headers: { Authorization: `Bearer ${token}` },
                          },
                        );

                        // Add the new job to the jobs list
                        setJobs((prevJobs) => [...prevJobs, response.data]);

                        // Switch to the job listing view
                        setCurrentView("jobListing");

                        // Show success message
                        toast.success("Job posted successfully!");
                      } catch (error) {
                        console.error("Error posting job:", error);
                        toast.error(
                          error.response?.data?.message || "Error posting job",
                        );
                      }
                    }}
                    company={company}
                  />
                </div>
              )}
              {currentView === "questionBuilder" && (
                <QuestionnaireBuilder selectedJob={selectedJob} />
              )}
              {currentView === "chatInterface" && (
                <ChatInterface
                  selectedJob={selectedJob}
                  onJobChange={handleJobChange}
                  jobs={jobs}
                  userType="c"
                />
              )}
              {currentView === "jobseekerList" && (
                <JobseekerList
                  selectedJob={selectedJob}
                  expandedJobseekerId={expandedJobseekerId}
                  initialJobseekers={jobseekers}
                  jobs={jobs}
                  currentUser={user}
                />
              )}
              {currentView === "jobListing" && (
                <JobListing
                  onJobSelected={(jobId) => {
                    setSelectedJob(jobId);
                    setCurrentView("questionBuilder");
                  }}
                  onViewJobseekers={(jobId) => {
                    setSelectedJob(jobId);
                    setCurrentView("jobseekerList");
                  }}
                  onViewSwimLanes={handleViewSwimLanes}
                  companyJobs={jobs}
                />
              )}
              {currentView === "swimLanes" && (
                <SwimLanes
                  selectedJob={selectedJob}
                  jobseekers={jobseekers}
                  setJobseekers={setJobseekers}
                  onNavigate={handleNavigate}
                  currentUser={user}
                />
              )}
              {currentView === "workerManagement" && (
                <WorkerManagement />
              )}
              {currentView === "companysettings" && (
                <CompanySettings company={company} onSave={handleCompanySave} />
              )}
              {currentView === "billing" && <Billing />}
            </div>
          </div>
        </NotificationProvider>
        <ToastContainer />
      </>
    );
  }

  export default App;
