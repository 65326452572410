import { api } from "./api";
import { jobQuestionnaire, jobseekerPendingDetails } from "../utils";
import defaultJobSeekerObBoardingQuestions from "../defaultJobSeekerObBoardingQuestions.json";

export const saveResponse = async (
  conversationId,
  question,
  response,
  messageType,
) => {
  const token = localStorage.getItem("token");
  return await api.post(
    "/api/chat/message",
    {
      question,
      message: response,
      conversationId,
      messageType,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  );
};

export const createConversation = async (type) => {
  const token = localStorage.getItem("token");
  return await api.post(
    "/api/chat/conversation",
    {
      participants: { role: type },
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  );
};

export const endConversation = async (
  conversation,
  type,
  total_time = null,
) => {
  return await api.put("/api/chat/end/conversation", {
    conversation,
    type,
    total_time,
  });
};

export const assessmentQuestionnaire = async (jobId, jobseeker) => {
  const pendingJobseekerDetails = await jobseekerPendingDetails(
    jobId,
    jobseeker,
  );

  const onboarding_question =
    defaultJobSeekerObBoardingQuestions.onboardingQuestions.jobseeker;
  let pending_details = onboarding_question.filter((element) => {
    if (pendingJobseekerDetails.indexOf(element.field) > -1) {
      return true;
    }
    return false;
  });

  const questions = await jobQuestionnaire(jobId);
  const assessment_questions = questions.map((entry) => {
    return {
      id: entry._id,
      category: "assessment",
      question: entry,
      question_text: entry.question_text,
      answer_type: "text",
      type: "text",
      required: true,
    };
  });

  const formated_questions = [...pending_details, ...assessment_questions];
  return formated_questions;
};

export const checkForToken = async (type, token = false) => {
  if (!token) {
    const searchParams = new URLSearchParams(window.location.search);
    token = searchParams.get("token");
  }
  try {
    await api.post("/api/jobseekers/verify/token", {
      token,
      type,
    });
    return true;
  } catch (error) {
    return false;
  }
};
