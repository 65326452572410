import React, { useState, useEffect, useCallback } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  MoreVertical,
  Phone,
  MessageSquare,
  Eye,
  EyeOff,
  UserCheck,
  UserX,
  Users,
  User,
} from "lucide-react";
import { useNotification } from "./CustomNotificationSystem";
import { api } from "./utils/api";
import {
  loadJobsJobseekers,
  hireJobseeker,
  rejectJobseeker,
  hideJobseeker,
  unHideJobseeker,
} from "./utils";
import Modal from "react-modal";
import MessageInterface from "./MessageInterface";
import { getNameInitials } from "./utils";

// Mock API function for updating jobseeker status (unchanged)
const updateJobseekerStatus = async (jobseekerId, newStatus) => {
  // Simulating API call
  const token = localStorage.getItem("token");
  try {
    await api.put(
      `/api/jobseekers/api/${jobseekerId}`,
      {
        applicationStatus: newStatus,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  } catch (error) {}
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({ success: true, message: "Status updated successfully" });
    }, 500);
  });
};

const SwimLanes = ({
  selectedJob,
  jobseekers,
  setJobseekers,
  onNavigate,
  currentUser,
}) => {
  const [showHidden, setShowHidden] = useState(false);
  const [filteredJobseekers, setFilteredJobseekers] = useState([]);
  const lanes = [
    "Applied",
    "Shortlisted",
    "Interview Booked",
    "Interviewed",
    "Hired",
  ];
  const handleCloseModal = () => {
    setModalState({ isOpen: false, type: null, jobSeekerId: null });
  };
  const [modalState, setModalState] = useState({
    isOpen: false,
    type: null,
    jobSeekerId: null,
  });
  const notify = useNotification();

  useEffect(() => {
    if (jobseekers && jobseekers.length > 0) {
      const filtered = jobseekers.filter(
        (js) => js.jobId === selectedJob && (showHidden || !js.hidden),
      );
      setFilteredJobseekers(filtered);
    } else {
      setFilteredJobseekers([]);
    }
  }, [selectedJob, showHidden, jobseekers]);

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const { source, destination, draggableId } = result;

    if (source.droppableId !== destination.droppableId) {
      const newStatus = destination.droppableId;
      const jobseekerId = draggableId;

      try {
        const response = await updateJobseekerStatus(jobseekerId, newStatus);

        if (response.success) {
          const updatedJobseekers = jobseekers.map((js) =>
            js._id === jobseekerId
              ? { ...js, applicationStatus: newStatus }
              : js,
          );
          setJobseekers(updatedJobseekers);
          notify("Jobseeker status updated successfully", "success");
        } else {
          notify("Failed to update jobseeker status", "error");
        }
      } catch (error) {
        console.error("Error updating jobseeker status:", error);
        notify("An error occurred while updating jobseeker status", "error");
      }
    }
  };

  const handleAction = async (action, jobseeker) => {
    if (action === "View Profile") {
      onNavigate("jobseekerList", jobseeker._id);
    } else if (action === "Call") {
      window.location = `tel: ${jobseeker.phone}`;
    } else if (action === "Hide") {
      hideJobseeker(jobseeker._id);
    } else if (action === "Hire") {
      if (window.confirm("Are you sure?")) {
        hireJobseeker(jobseeker._id);
        notify("Jobseeker is marked as Hired", "success");
      }
    } else if (action === "Message") {
      setModalState({
        isOpen: true,
        type: "message",
        jobSeekerId: [{ name: jobseeker._id, id: jobseeker.name }],
      });
    } else if (action === "Reject") {
      rejectJobseeker(jobseeker._id);
    } else if (action === "Unhide") {
      unHideJobseeker(jobseeker._id);
    }

    const jobseekers = await loadJobsJobseekers(jobseeker.jobId);
    setJobseekers(jobseekers);
  };

  const ActionMenu = ({ jobseeker }) => {
    const [isOpen, setIsOpen] = useState(false);

    const actions = [
      { name: "Call", icon: <Phone size={16} /> },
      {
        name: jobseeker.hidden ? "Unhide" : "Hide",
        icon: jobseeker.hidden ? <Eye size={16} /> : <EyeOff size={16} />,
      },
      { name: "Hire", icon: <UserCheck size={16} /> },
      { name: "Message", icon: <MessageSquare size={16} /> },
      { name: "Reject", icon: <UserX size={16} /> },
      { name: "View Profile", icon: <User size={16} /> },
    ];

    return (
      <div className="relative">
        <button
          className="p-1 rounded-full hover:bg-gray-200 transition duration-150 ease-in-out"
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(!isOpen);
          }}
        >
          <MoreVertical size={20} />
        </button>
        {isOpen && (
          <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-50">
            {actions.map((action) => (
              <button
                key={action.name}
                className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition duration-150 ease-in-out"
                onClick={(e) => {
                  e.stopPropagation();
                  handleAction(action.name, jobseeker);
                  setIsOpen(false);
                }}
              >
                {action.icon}
                <span className="ml-2">{action.name}</span>
              </button>
            ))}
          </div>
        )}
      </div>
    );
  };

  const getScoreColor = (score) => {
    if (score >= 80) return "border-green-500 text-green-700";
    if (score >= 60) return "border-yellow-500 text-yellow-700";
    return "border-red-500 text-red-700";
  };

  const renderModalContent = () => {
    switch (modalState.type) {
      case "message":
        return (
          <MessageInterface
            jobseeker={modalState.jobSeekerId}
            companyUser={currentUser}
          />
        );
      default:
        return null;
    }
  };

  const JobseekerTile = ({ jobseeker, index }) => (
    <Draggable draggableId={jobseeker._id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={`relative bg-gradient-to-r from-white to-gray-100 p-4 mb-3 rounded-lg shadow-md transition-all duration-200 ${
            snapshot.isDragging ? "shadow-lg scale-105" : ""
          }`}
          style={{
            ...provided.draggableProps.style,
          }}
        >
          <div className="absolute top-2 right-2 flex items-center">
            <div
              className={`w-8 h-8 rounded-full border-2 ${getScoreColor(jobseeker.score)} flex items-center justify-center font-semibold text-sm`}
            >
              {jobseeker.score}
            </div>
          </div>
          <div className="flex items-center justify-between">
            {jobseeker.avatar ? (
              <img
                src={jobseeker.avatar || "https://via.placeholder.com/40"}
                alt={jobseeker.name}
                className="w-12 h-12 rounded-full mr-3 object-cover border-2 border-gray-200"
              />
            ) : (
              <span className="w-10 h-10 bg-indigo-500 text-white rounded-full flex items-center justify-center font-bold text-sm mr-4">
                {getNameInitials(jobseeker.name)}
              </span>
            )}
            <div className="flex-grow">
              <h3 className="font-semibold text-indigo-700">
                {jobseeker.name}
              </h3>
              <p className="text-sm text-gray-600">{jobseeker.jobTitle}</p>
              <div className="flex items-center mt-1">
                <span className="text-xs bg-blue-100 text-blue-800 px-2 py-1 rounded-full mr-2">
                  {jobseeker.location}
                </span>
              </div>
            </div>
            <ActionMenu jobseeker={jobseeker} />
          </div>
        </div>
      )}
    </Draggable>
  );

  return (
    <div className="flex flex-col h-full bg-gray-100 p-6 lg:p-8">
      <div className="mb-6 flex justify-between items-center">
        <h2 className="text-3xl font-bold text-indigo-800 flex items-center">
          <Users className="mr-2" /> Swim Lanes
        </h2>
        <label className="flex items-center space-x-2 bg-white px-4 py-2 rounded-lg shadow-md">
          <input
            type="checkbox"
            checked={showHidden}
            onChange={() => setShowHidden(!showHidden)}
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
          />
          <span className="text-indigo-700 font-semibold">
            Show Hidden Jobseekers
          </span>
        </label>
      </div>
      {filteredJobseekers.length > 0 ? (
        <div className="flex-grow overflow-hidden">
          <DragDropContext onDragEnd={onDragEnd}>
            <div className="flex space-x-4 h-full overflow-x-auto pb-4">
              {lanes.map((lane, index) => (
                <div key={lane} className="flex-1 min-w-[280px] flex flex-col">
                  <h3
                    className={`font-semibold p-3 text-center shadow-md rounded-t-lg text-white ${
                      index % 2 === 0 ? "bg-indigo-400" : "bg-blue-400"
                    }`}
                  >
                    {lane}
                  </h3>
                  <Droppable droppableId={lane}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className={`flex-grow overflow-y-auto p-3 bg-gray-50 rounded-b-lg shadow-inner transition-all duration-200 ${
                          snapshot.isDraggingOver ? "bg-indigo-50" : ""
                        }`}
                        style={{ minHeight: "calc(75vh - 2rem)" }}
                      >
                        {filteredJobseekers
                          .filter((js) => js.applicationStatus === lane)
                          .map((jobseeker, index) => (
                            <JobseekerTile
                              key={jobseeker._id}
                              jobseeker={jobseeker}
                              index={index}
                            />
                          ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              ))}
            </div>
          </DragDropContext>
        </div>
      ) : (
        <div className="text-center py-12 bg-white rounded-lg shadow-md">
          <p className="text-xl text-gray-600">
            No jobseekers found for this job.
          </p>
        </div>
      )}
      <Modal
        isOpen={modalState.isOpen}
        onRequestClose={handleCloseModal}
        contentLabel={`JobSeeker Action: ${modalState.type}`}
        className="max-w-md mx-auto mt-20 p-6 bg-white rounded-lg shadow-xl"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
      >
        {renderModalContent()}
      </Modal>
    </div>
  );
};

export default SwimLanes;
