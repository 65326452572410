// Constants
import { api } from "./utils/api";

export const AnswerTypes = {
  OPEN_ENDED: "Open-ended",
  YES_NO: "Yes/No",
  MULTIPLE_CHOICE: "Multiple Choice",
  RATING: "Rating",
};

export const DifficultyLevels = {
  EASY: "Easy",
  MEDIUM: "Medium",
  HARD: "Hard",
};

export const ImportanceLevels = {
  LOW: "Low",
  MEDIUM: "Medium",
  HIGH: "High",
  CRITICAL: "Critical",
};

export const ConditionTypes = {
  YES_NO: "yes_no",
  KEYWORD: "keyword",
  RATING: "rating",
  LENGTH: "length",
  CONFIDENCE: "confidence",
  COMPLETENESS: "completeness",
};

// Helper functions
export const generateUniqueId = () => Date.now().toString();

export const getDefaultConditionValue = (conditionType) => {
  switch (conditionType) {
    case ConditionTypes.YES_NO:
      return "yes";
    case ConditionTypes.KEYWORD:
      return { matchType: "matched", keywords: "" };
    case ConditionTypes.RATING:
      return { type: "lessThan", value: 5 };
    case ConditionTypes.LENGTH:
      return { type: "lessThan", value: 0 };
    case ConditionTypes.CONFIDENCE:
    case ConditionTypes.COMPLETENESS:
      return 50;
    default:
      return "";
  }
};

export const isValidURL = (string) => {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
};

export const hireJobseeker = async (jobseekerId) => {
  const token = localStorage.getItem("token");
  try {
    await api.put(
      `/api/jobseekers/api/${jobseekerId}`,
      {
        applicationStatus: "Hired",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return true;
  } catch (error) {
    return false;
  }
};

export const rejectJobseeker = async (jobseekerId) => {
  const token = localStorage.getItem("token");
  try {
    await api.put(
      `/api/jobseekers/api/${jobseekerId}`,
      {
        applicationStatus: "Rejected",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return true;
  } catch (error) {
    return false;
  }
};

export const hideJobseeker = async (jobseekerId) => {
  const token = localStorage.getItem("token");
  try {
    await api.put(
      `/api/jobseekers/api/${jobseekerId}`,
      {
        hidden: true,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return true;
  } catch (error) {
    return false;
  }
};

export const unHideJobseeker = async (jobseekerId) => {
  const token = localStorage.getItem("token");
  try {
    await api.put(
      `/api/jobseekers/api/${jobseekerId}`,
      {
        hidden: false,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return true;
  } catch (error) {
    return false;
  }
};

export const getNameInitials = (name) => {
  // Trim the name and split it into words
  const words = name.trim().split(/\s+/);

  // If the name is empty, return an empty string
  if (words.length === 0) return "";

  // If it's a single word, return the first two characters (or the first character if it's a single-character word)
  if (words.length === 1) {
    return words[0].substring(0, 2).toUpperCase();
  }

  // Otherwise, return the first character of the first word and the first character of the last word
  return (words[0][0] + words[words.length - 1][0]).toUpperCase();
};

export const loadJobsJobseekers = async (jobId) => {
  const token = localStorage.getItem("token");
  const response = await api.get(`/admin/jobseekers/api/job?jobId=${jobId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export const uploadFiles = async (files) => {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append("files", file);
    //console.log("Appending file to FormData:", file.name, file.type, file.size);
  });

  try {
    const response = await api.post("/api/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data.fileUrls;
  } catch (error) {
    console.error(
      "Error uploading files:",
      error.response ? error.response.data : error.message,
    );
    throw error;
  }
};

export const generateQuestionsWithAI = async (job) => {
  const token = localStorage.getItem("token");
  const response = await api.get(
    `/api/jobs/generate/questionnaire?jobId=${job}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  );
  return response.data;
};

export const jobQuestionnaire = async (job) => {
  const token = localStorage.getItem("token");
  const response = await api.get(`/api/jobs/questionnaire?jobId=${job}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export const chaseJobseekerForQuestionnaire = async (jobseeker, job) => {
  const token = localStorage.getItem("token");
  const response = await api.post(
    `/api/jobs/questionnaire/invite`,
    { jobseeker, job },
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  );
  return response.data;

  /* return (
    process.env.REACT_APP_SITE_URL +
    `?mode=chat&for=a&j=${jobseekerId}&job=${selectedJob}`
  ); */
};

export const jobseekerPendingDetails = async (jobId, jobseeker) => {
  const response = await api.get(
    `/api/jobseekers/pending/details?job=${jobId}&jobseeker=${jobseeker}`,
  );
  return response.data;
};

export const capitalizeWords = (str) => {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export const calculatePercentile = (data, value) => {
  // Step 1: Sort the data in ascending order
  data.sort((a, b) => a - b);

  // Step 2: Find the rank of the value in the sorted dataset
  let rank = data.findIndex((item) => item === value) + 1; // rank is 1-based

  if (rank === 0) {
    return null; // Value not found in the dataset
  }

  // Step 3: Apply the percentile formula
  let percentile = ((rank - 0.5) / data.length) * 100;

  return percentile;
};

export const getChecklistForJob = async (jobid) => {
  const checklist = await api.get(`/api/jobs/checklist/${jobid}`);
  return checklist.data;
};

export const saveCheckList = async (jobid, jobseekerid, checklist) => {
  const token = localStorage.getItem("token");
  const saved = await api.post(
    `/api/jobseekers/checklist`,
    { jobid, jobseekerid, checklist },
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  );
  return saved.data;
};

export const saveQuestion = async ({questions, jobId}) => {
  const token = localStorage.getItem("token");
  const saved = await api.post(
    `/api/jobs/save/questionnaire`,
    { questions, jobId },
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  );
  return saved.data;
};
