// services/DummyDataService.js

export const JobDataService = {
  industries: [
    {
      id: "tech",
      name: "Technology",
      description: "Software, IT Services, Hardware",
    },
    {
      id: "finance",
      name: "Finance & Banking",
      description: "Banking, Insurance, Investment",
    },
    {
      id: "healthcare",
      name: "Healthcare & Medical",
      description: "Hospitals, Medical Services, Pharmaceuticals",
    },
    {
      id: "education",
      name: "Education",
      description: "Schools, Universities, Training",
    },
    {
      id: "retail",
      name: "Retail & Consumer",
      description: "Shops, E-commerce, Consumer Goods",
    },
    {
      id: "manufacturing",
      name: "Manufacturing",
      description: "Production, Engineering, Assembly",
    },
    {
      id: "construction",
      name: "Construction",
      description: "Building, Architecture, Development",
    },
    {
      id: "hospitality",
      name: "Hospitality & Tourism",
      description: "Hotels, Restaurants, Tourism",
    },
    {
      id: "marketing",
      name: "Marketing & Advertising",
      description: "Digital Marketing, Advertising, PR",
    },
    {
      id: "transport",
      name: "Transport & Logistics",
      description: "Transportation, Shipping, Logistics",
    },
  ],

  qualifications: [
    { id: "ipaf", name: "IPAF" },
    { id: "cscs", name: "CSCS" },
    { id: "jib", name: "JIB Card" },
    { id: "nvq", name: "NVQ Level 3 Electrical Installation" },
    { id: "18th-edition", name: "18th Edition Wiring Regulations" },
    { id: "other", name: "Other" },
  ],

  skills: [
    { id: "accepting-feedback", name: "Accepting feedback" },
    { id: "adaptability", name: "Adaptability" },
    { id: "artistic-aptitude", name: "Artistic aptitude" },
    { id: "assertiveness", name: "Assertiveness" },
    { id: "attentiveness", name: "Attentiveness" },
    { id: "business-ethics", name: "Business ethics" },
    { id: "business-storytelling", name: "Business storytelling" },
    { id: "business-trend-awareness", name: "Business trend awareness" },
    { id: "collaboration", name: "Collaboration" },
    { id: "competitiveness", name: "Competitiveness" },
    { id: "confidence", name: "Confidence" },
    { id: "conflict-management", name: "Conflict management" },
    { id: "conflict-resolution", name: "Conflict resolution" },
    { id: "cooperation", name: "Cooperation" },
    { id: "courtesy", name: "Courtesy" },
    { id: "creativity", name: "Creativity" },
    { id: "critical-observation", name: "Critical observation" },
    { id: "critical-thinking", name: "Critical thinking" },
    { id: "customer-service", name: "Customer service" },
    { id: "deal-making", name: "Deal-making" },
    {
      id: "dealing-with-difficult-personalities",
      name: "Dealing with difficult personalities",
    },
    {
      id: "dealing-with-difficult-situations",
      name: "Dealing with difficult situations",
    },
    {
      id: "dealing-with-office-politics",
      name: "Dealing with office politics",
    },
    { id: "decision-making", name: "Decision-making" },
    { id: "dedication", name: "Dedication" },
    { id: "delegation", name: "Delegation" },
    { id: "dependability", name: "Dependability" },
    { id: "design-aptitude", name: "Design aptitude" },
    { id: "desire-to-learn", name: "Desire to learn" },
    { id: "disability-awareness", name: "Disability awareness" },
    { id: "dispute-resolution", name: "Dispute resolution" },
    { id: "diversity-awareness", name: "Diversity awareness" },
    { id: "effective-communicator", name: "Effective communicator" },
    { id: "emotion-management", name: "Emotion management" },
    { id: "emotional-intelligence", name: "Emotional intelligence" },
    { id: "empathy", name: "Empathy" },
    { id: "energy", name: "Energy" },
    { id: "enthusiasm", name: "Enthusiasm" },
    { id: "ergonomic-sensitivity", name: "Ergonomic sensitivity" },
    {
      id: "establishing-interpersonal-relationships",
      name: "Establishing interpersonal relationships",
    },
    { id: "facilitation", name: "Facilitation" },
    { id: "flexibility", name: "Flexibility" },
    { id: "following-direction", name: "Following direction" },
    { id: "follows-instructions", name: "Follows instructions" },
    { id: "follows-regulations", name: "Follows regulations" },
    { id: "follows-rules", name: "Follows rules" },
    { id: "friendliness", name: "Friendliness" },
    {
      id: "functions-well-under-pressure",
      name: "Functions well under pressure",
    },
    { id: "giving-clear-feedback", name: "Giving clear feedback" },
    { id: "good-attitude", name: "Good attitude" },
    { id: "highly-recommended", name: "Highly recommended" },
    { id: "honesty", name: "Honesty" },
    { id: "humor", name: "Humor" },
    { id: "independence", name: "Independence" },
    { id: "independent", name: "Independent" },
    { id: "influence", name: "Influence" },
    { id: "innovation", name: "Innovation" },
    { id: "inspiring-people", name: "Inspiring people" },
    { id: "intercultural-competence", name: "Intercultural competence" },
    { id: "interpersonal-skills", name: "Interpersonal skills" },
    { id: "interviewing", name: "Interviewing" },
    { id: "knowledge-management", name: "Knowledge management" },
    { id: "leadership", name: "Leadership" },
    { id: "listening", name: "Listening" },
    { id: "logical-thinking", name: "Logical thinking" },
    { id: "management", name: "Management" },
    {
      id: "managing-difficult-conversations",
      name: "Managing difficult conversations",
    },
    {
      id: "managing-remote-virtual-teams",
      name: "Managing remote/virtual teams",
    },
    { id: "meeting-deadlines", name: "Meeting deadlines" },
    { id: "meeting-management", name: "Meeting management" },
    { id: "meets-deadlines", name: "Meets deadlines" },
    { id: "mentoring", name: "Mentoring" },
    { id: "motivating", name: "Motivating" },
    { id: "motivation", name: "Motivation" },
    { id: "multitasking", name: "Multitasking" },
    { id: "negotiation", name: "Negotiation" },
    { id: "networking", name: "Networking" },
    { id: "nonverbal-communication", name: "Nonverbal communication" },
    { id: "organization", name: "Organization" },
    { id: "patience", name: "Patience" },
    { id: "performance-management", name: "Performance management" },
    {
      id: "performs-effectively-in-a-deadline-environment",
      name: "Performs effectively in a deadline environment",
    },
    { id: "perseverance", name: "Perseverance" },
    { id: "persistence", name: "Persistence" },
    { id: "persuasion", name: "Persuasion" },
    { id: "planning", name: "Planning" },
    { id: "positive-work-ethic", name: "Positive work ethic" },
    { id: "presentation", name: "Presentation" },
    { id: "problem-solving", name: "Problem-solving" },
    { id: "process-improvement", name: "Process improvement" },
    { id: "project-management", name: "Project management" },
    { id: "proper-business-etiquette", name: "Proper business etiquette" },
    { id: "public-speaking", name: "Public speaking" },
    { id: "punctuality", name: "Punctuality" },
    { id: "quick-witted", name: "Quick-witted" },
    { id: "reading-body-language", name: "Reading body language" },
    { id: "reliability", name: "Reliability" },
    { id: "research-skills", name: "Research skills" },
    { id: "resilience", name: "Resilience" },
    { id: "resolving-issues", name: "Resolving issues" },
    { id: "resourcefulness", name: "Resourcefulness" },
    { id: "respectability", name: "Respectability" },
    { id: "respectfulness", name: "Respectfulness" },
    { id: "results-oriented", name: "Results-oriented" },
    { id: "safety-conscious", name: "Safety conscious" },
    { id: "scheduling", name: "Scheduling" },
    { id: "self-awareness", name: "Self-awareness" },
    { id: "self-directed", name: "Self-directed" },
    { id: "self-monitoring", name: "Self-monitoring" },
    { id: "self-supervising", name: "Self-supervising" },
    { id: "selling-skills", name: "Selling skills" },
    { id: "social-skills", name: "Social skills" },
    { id: "staying-on-task", name: "Staying on task" },
    { id: "storytelling", name: "Storytelling" },
    { id: "strategic-planning", name: "Strategic planning" },
    { id: "stress-management", name: "Stress management" },
    { id: "successful-coaching", name: "Successful coaching" },
    { id: "supervising", name: "Supervising" },
    { id: "talent-management", name: "Talent management" },
    { id: "team-building", name: "Team building" },
    { id: "team-player", name: "Team player" },
    { id: "teamwork", name: "Teamwork" },
    { id: "technology-savvy", name: "Technology savvy" },
    { id: "technology-trend-awareness", name: "Technology trend awareness" },
    { id: "thinking-outside-the-box", name: "Thinking outside the box" },
    { id: "time-management", name: "Time management" },
    {
      id: "tolerance-of-change-and-uncertainty",
      name: "Tolerance of change and uncertainty",
    },
    { id: "tolerant", name: "Tolerant" },
    { id: "trainability", name: "Trainability" },
    { id: "trainable", name: "Trainable" },
    { id: "training", name: "Training" },
    { id: "troubleshooting-skills", name: "Troubleshooting skills" },
    { id: "value-education", name: "Value education" },
    { id: "verbal-communication", name: "Verbal communication" },
    { id: "visual-communication", name: "Visual communication" },
    { id: "willing-to-accept-feedback", name: "Willing to accept feedback" },
    { id: "willingness-to-learn", name: "Willingness to learn" },
    { id: "work-life-balance", name: "Work-life balance" },
    { id: "working-well-under-pressure", name: "Working well under pressure" },
    { id: "works-well-under-pressure", name: "Works well under pressure" },
    {
      id: "writing-reports-and-proposals",
      name: "Writing reports and proposals",
    },
    { id: "writing-skills", name: "Writing skills" },
    { id: "communication", name: "Communication" },
    { id: "fault-finding", name: "Fault Finding" },
    { id: "installation", name: "Electrical Installation" },
    { id: "testing", name: "Testing and Commissioning" },
  ],

  additional_requirements: [
    { id: "Full UK Driving Licence", value: "Full UK Driving Licence" },
    {
      id: "If you are interested in this work or you know someone who is interested please give me a call",
      value:
        "If you are interested in this work or you know someone who is interested please give me a call",
    },
    { id: "No digs", value: "No digs" },
    { id: "Must have own PPE", value: "Must have own PPE" },
    { id: "Must have own tools", value: "Must have own tools" },
    { id: "Must speak English", value: "Must speak English" },
    { id: "Must have experience", value: "Must have experience" },
    { id: "Must have VISA", value: "Must have VISA" },
    { id: "Pass drugs & alcohol test", value: "Pass drugs & alcohol test" },
    {
      id: "Settlement Status or a Reference number",
      value: "Settlement Status or a Reference number",
    },
  ],

  getQualifications: () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(JobDataService.qualifications);
      }, 300);
    });
  },

  getSkills: () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(JobDataService.skills);
      }, 300);
    });
  },

  additionalRequirements: () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(JobDataService.additional_requirements);
      }, 300);
    });
  },

  // Add a new function to get industries
  getIndustries: () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(JobDataService.industries);
      }, 300);
    });
  },

  getGenericData: () => {
    return [
      "Adaptability: Employees may be required to adapt to changing situations and environments.",
      "Appearance: Employees may be required to follow a dress code or adhere to certain grooming standards.",
      "Attendance and punctuality: Employees are expected to be at work on time and ready to start their shift.",
      "Attention to detail: Employees may be expected to pay close attention to detail and follow blueprints and plans accurately.",
      "Collaboration: Employees may be required to work in teams and contribute to the overall effort.",
      "Collaboration: Employees may be required to work in teams or groups and contribute to the overall effort.",
      "Communication: Employees are expected to communicate effectively with their coworkers, supervisors, and customers.",
      "Communication: Employees may be expected to communicate with their team, supervisors, and clients effectively.",
      "Efficiency: Employees may be expected to work efficiently and use their time and resources wisely.",
      "Flexibility: Employees may be required to adapt to changing circumstances or priorities on the job site.",
      "Knowledge of tools and equipment: Employees may be expected to be proficient in the use of various tools and equipment.",
      "Physical fitness: Employees may be required to perform physically demanding tasks and lift heavy objects.",
      "Problem-solving skills: Employees may be expected to find solutions to problems that arise during the construction process.",
      "Professionalism: Employees are expected to behave in a professional manner and demonstrate respect for others.",
      "Quality control: Employees may be responsible for ensuring that the work meets certain standards of quality.",
      "Quality: Employees may be expected to produce work of a high quality and meet certain standards.",
      "Responsibility: Employees are expected to be responsible and accountable for their work and actions.",
      "Safety training: Employees may be required to complete safety training and follow safety protocols on the job site.",
      "Safety: Employees may be required to follow safety protocols and procedures to protect themselves and others.",
      "Time management: Employees may be expected to manage their time effectively and meet project deadlines.",
    ];
  },
};

export default JobDataService;
