import axios from "axios";
import { api } from "../utils/api";

const VERITONE_API_URL =
  "https://beanwidget.adcourier.com/distro/dashboardpost";

// This would come from your environment variables
const API_CONFIG = {
  api_key: process.env.REACT_APP_VERITONE_API_KEY,
  client_id: process.env.REACT_APP_VERITONE_CLIENT_ID,
  adc_username: process.env.REACT_APP_VERITONE_USERNAME,
};

// Helper function to generate signature (you'll need to implement this based on your requirements)
const generateSignature = () => {
  const time = Date.now();
  // Implementation needed for signature generation
  return {
    time,
    signature: "dummy-signature", // Replace with actual signature generation
  };
};

export const JobService = {
  // Transform our form data to Veritone API format
  transformFormDataToVeritoneFormat: (formData) => {
    return {
      authentication: {
        api_key: API_CONFIG.api_key,
        ...generateSignature(),
      },
      identification: {
        client_id: API_CONFIG.client_id,
        adc_username: API_CONFIG.adc_username,
      },
      transaction: {
        filters: {
          job_reference: formData.job_refreneces,
        },
        advert: {
          job_title: formData.title,
          job_reference: formData.job_refreneces,
          job_type: formData.job_type,
          location_query: `${formData.city}, ${formData.region}`,
          industry: formData.industry,
          salary_from:
            formData.show_pay_by === "Range"
              ? formData.salary_min
              : formData.salary,
          salary_to:
            formData.show_pay_by === "Range"
              ? formData.salary_max
              : formData.salary,
          salary_currency: "gbp",
          salary_per: formData.salarytype,
          skills: formData.preferred_skills.join(", "),
          summary: formData.generic_job_detail,
          job_description: formData.description,
          client_details: formData.company_overview_text,
          startdate: formData.start_date,
          experience: formData.min_year_exp
            ? [formData.min_year_exp]
            : undefined,
        },
      },
    };
  },

  // Create a new job posting
  createJob: async (formData) => {
    try {
      /*
      const veritoneData =
        JobService.transformFormDataToVeritoneFormat(formData);
      const response = await axios.post(VERITONE_API_URL, veritoneData);

      if (response.data.success) {
        // Store the job data in your local database along with the Veritone URL
        const localJobData = {
          ...formData,
          veritoneUrl: response.data.url,
          status: "active",
        };

        // Make API call to your backend to store the job
        const localResponse = await axios.post("/api/jobs", localJobData);
        return localResponse.data;
        } else {
          throw new Error("Failed to create job in Veritone");
        }
      */
      const localJobData = {
        ...formData,
      };
      const localResponse = await api.post("/api/jobs/add", localJobData);
      return localResponse.data;
    } catch (error) {
      console.error("Error creating job:", error);
      throw error;
    }
  },

  // Get job management dashboard URL
  getJobManagementUrl: async (jobReference) => {
    try {
      const payload = {
        authentication: {
          api_key: API_CONFIG.api_key,
          ...generateSignature(),
        },
        identification: {
          client_id: API_CONFIG.client_id,
          adc_username: API_CONFIG.adc_username,
        },
        transaction: {
          options: {
            behaviour_if_not_advert_owner: "view",
          },
          filters: {
            job_reference: jobReference,
          },
          advert: {
            job_reference: jobReference,
          },
        },
      };

      const response = await axios.post(VERITONE_API_URL, payload);
      return response.data.url;
    } catch (error) {
      console.error("Error getting management URL:", error);
      throw error;
    }
  },

  // Delete a job
  deleteJob: async (jobId, jobReference) => {
    try {
      // First delete from Veritone
      const managementUrl = await JobService.getJobManagementUrl(jobReference);
      // You would need to implement the actual deletion through the management interface

      // Then delete from your local database
      await axios.delete(`/api/jobs/${jobId}`);
      return true;
    } catch (error) {
      console.error("Error deleting job:", error);
      throw error;
    }
  },

  // Update a job
  updateJob: async (jobId, formData) => {
    try {
      const veritoneData =
        JobService.transformFormDataToVeritoneFormat(formData);
      const response = await axios.post(VERITONE_API_URL, veritoneData);

      if (response.data.success) {
        // Update the job in your local database
        const localResponse = await axios.put(`/api/jobs/${jobId}`, {
          ...formData,
          veritoneUrl: response.data.url,
        });
        return localResponse.data;
      } else {
        throw new Error("Failed to update job in Veritone");
      }
    } catch (error) {
      console.error("Error updating job:", error);
      throw error;
    }
  },

  getPreviousJobs: async (company) => {
    try {
      const token = localStorage.getItem("token");
      const response = await api.get(`/api/jobs/api/company/${company._id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const jobs = response.data;
      const list = jobs.map((job) => {
        const advert = job.jobAdvert;
        const basic_info = advert.basicInfo;
        const jobDetails = advert.jobDetails;

        let show_exact = true;
        if (jobDetails.salary.min && jobDetails.salary.max) {
          show_exact = false;
        }
        let formattedDate = "";
        if (jobDetails.startDate.date) {
          formattedDate = new Date(jobDetails.startDate.date)
            .toISOString()
            .split("T")[0];
        }

        return {
          id: job._id,
          job_refreneces: job.jobReference,
          title: basic_info.jobTitle,
          number_of_hires: basic_info.numberOfHires,
          addressgeo: basic_info?.location?.details?.city,
          postcode: basic_info?.location?.details?.postcode,
          city: basic_info?.location?.details?.city,
          region: basic_info?.location?.details?.region,
          generic_job_detail: basic_info?.generic_job_detail,
          industry: basic_info?.industry,
          job_type: jobDetails?.jobType?.value,
          is_graduate_job: jobDetails?.is_graduate_job,
          start_date: formattedDate,
          salary: jobDetails?.salary?.exactAmount?.amount,
          salarytype: jobDetails?.salary?.exactAmount?.frequency.value,
          start_asap: jobDetails.startDate.ASAP,
          show_pay_by: show_exact ? "Exact" : "",
          hours: advert?.workingConditions?.hours?.otherPatterns?.value,
          hours_from: advert?.workingConditions?.hours?.defined?.from,
          hours_till: advert?.workingConditions?.hours?.defined?.to,
          qualifications: advert?.qualifications.required,
          preferred_qualifications: advert?.qualifications.preferred,
          preferred_skills: advert?.qualifications.preferredSkills,
          description: advert.description,
          education: advert.qualifications.education,
          additional_requirements: advert.qualifications.additionalRequirements,
          min_year_exp: advert.qualifications.experience.minimumYears?.value,
          remoteType: advert.qualifications.remoteType?.value,
          cv_required: advert.documentRequirements.CVRequired,
          is_references_required:
            advert.documentRequirements.referencesRequired.required,
          references_required_options:
            advert.documentRequirements.referencesRequired.minimumReferences
              .value ?? 2,
          company: job.company.name,
          company_email: job.company.contactEmail,
          phone: job.company.contactPhone,
          company_website: job.company.website,
          company_slogan: job.company?.slogan,
          company_overview_text: job.company?.company_overview,
          company_id: company._id,
        };
      });
      return list;
    } catch (error) {
      return [];
    }
  },
};

export default JobService;
