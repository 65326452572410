import React from "react";

const TermsStep = ({ formData, onChange }) => {
  return (
    <div className="flex flex-col gap-4 max-w-[456px]">
      {/* Job Type and Graduate Job */}
      <div className="relative">
        <label className="block text-gray-700 text-sm font-medium mb-2">
          Job Type <span className="text-red-500 font-bold">*</span>
        </label>
        <div className="absolute top-0 right-0">
          <label className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={formData.is_graduate_job}
              onChange={(e) => onChange("is_graduate_job", e.target.checked)}
              className="rounded"
            />
            <span className="text-sm">Graduate Job</span>
          </label>
        </div>
        <select
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
          value={formData.job_type.toLowerCase()}
          onChange={(e) => onChange("job_type", e.target.value)}
        >
          <option value="">Select Job Type</option>
          <option value="full time">Full Time</option>
          <option value="part time">Part Time</option>
          <option value="contract">Contract</option>
          <option value="temporary">Temporary</option>
          <option value="internship">Internship</option>
        </select>
      </div>

      {/* Start Date Section */}
      <div>
        <label className="block text-gray-700 text-sm font-medium mb-2">
          Start Date <span className="text-red-500 font-bold">*</span>
        </label>
        <div className="flex gap-4 mb-2">
          <label className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={formData.start_asap}
              onChange={(e) => onChange("start_asap", e.target.checked)}
              className="rounded"
            />
            <span className="text-sm">ASAP</span>
          </label>
          <label className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={formData.no_start_date}
              onChange={(e) => onChange("no_start_date", e.target.checked)}
              className="rounded"
            />
            <span className="text-sm">No Start Date</span>
          </label>
        </div>
        <input
          type="date"
          className="w-[234px] px-3 py-2 border border-gray-300 rounded-md"
          value={formData.start_date}
          onChange={(e) => onChange("start_date", e.target.value)}
          disabled={formData.start_asap || formData.no_start_date}
        />
      </div>
      {/* Salary Section */}
      <div>
        <div className="flex gap-2 mb-2">
          <label className="block text-gray-700 text-sm font-medium">
            Salary <span className="text-red-500 font-bold">*</span>
          </label>
          <label className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={formData.is_salary_based_exp}
              onChange={(e) =>
                onChange("is_salary_based_exp", e.target.checked)
              }
              className="rounded"
            />
            <span className="text-sm">Based on experience</span>
          </label>
        </div>

        <div className="w-[234px] mb-4">
          <select
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
            value={formData.show_pay_by}
            onChange={(e) => onChange("show_pay_by", e.target.value)}
          >
            <option value="">Show Pay By</option>
            <option value="Exact">Exact Amount</option>
            <option value="Range">Range</option>
          </select>
        </div>

        <div className="flex gap-2">
          <div className="w-1/2">
            {formData.show_pay_by === "Range" ? (
              <div className="space-y-2">
                <div className="relative">
                  <span className="absolute left-3 top-2">£</span>
                  <input
                    type="number"
                    placeholder="Min"
                    className="w-full pl-8 pr-3 py-2 border border-gray-300 rounded-md"
                    value={formData.salary_min}
                    onChange={(e) => onChange("salary_min", e.target.value)}
                  />
                </div>
                <div className="relative">
                  <span className="absolute left-3 top-2">£</span>
                  <input
                    type="number"
                    placeholder="Max"
                    className="w-full pl-8 pr-3 py-2 border border-gray-300 rounded-md"
                    value={formData.salary_max}
                    onChange={(e) => onChange("salary_max", e.target.value)}
                  />
                </div>
              </div>
            ) : (
              <div className="relative">
                <span className="absolute left-3 top-2">£</span>
                <input
                  type="number"
                  className="w-full pl-8 pr-3 py-2 border border-gray-300 rounded-md"
                  value={formData.salary}
                  onChange={(e) => onChange("salary", e.target.value)}
                />
              </div>
            )}
          </div>
          <div className="w-1/2">
            <select
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              value={formData.salarytype}
              onChange={(e) => onChange("salarytype", e.target.value)}
            >
              <option value="">Salary Type</option>
              <option value="yearly">per year</option>
              <option value="monthly">per month</option>
              <option value="weekly">per week</option>
              <option value="daily">per day</option>
              <option value="hourly">per hour</option>
            </select>
          </div>
        </div>
      </div>

      <div>
        <div className="mb-2">
          <label className="inline-block text-gray-700 text-sm font-medium">
            Working Hours
            <span className="text-red-500 font-bold">*</span>
          </label>
        </div>

        <div className="flex gap-4">
          <div className="w-1/3 space-y-2">
            <span className="text-sm">Hours</span>
            <select
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              value={formData.hours}
              onChange={(e) => onChange("hours", e.target.value)}
            >
              <option value="">Select Hrs</option>
              {Array.from({ length: 24 }, (_, i) => (
                <option key={i + 1} value={i + 1}>
                  {i + 1}
                </option>
              ))}
            </select>
          </div>

          <div className="w-1/3">
            <span className="text-sm">From Hour</span>
            <input
              type="time"
              id="hours_from"
              value={formData.hours_from}
              name="hours_from"
              placeholder="From Hours"
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              aria-invalid="false"
              onChange={(e) => onChange("hours_from", e.target.value)}
            />
          </div>

          <div className="w-1/3">
            <span className="text-sm">To Hour</span>
            <input
              type="time"
              id="hours_to"
              value={formData.hours_till}
              name="hours_to"
              placeholder="To Hours"
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              aria-invalid="false"
              onChange={(e) => onChange("hours_till", e.target.value)}
            />
          </div>
        </div>
      </div>

      {/* Breaks Section */}
      <div>
        <div className="mb-2">
          <label className="inline-block text-gray-700 text-sm font-medium">
            Breaks
          </label>
        </div>
        <div className="flex items-center gap-4">
          <div className="w-[160px]">
            <select
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              value={formData.break_duration_min}
              onChange={(e) => onChange("break_duration_min", e.target.value)}
            >
              <option value="">Duration</option>
              {[...Array(60)].map((_, i) => (
                <option key={i + 1} value={i + 1}>
                  {i + 1} minutes
                </option>
              ))}
            </select>
          </div>
          <label className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={formData.is_break_paid}
              onChange={(e) => onChange("is_break_paid", e.target.checked)}
              className="rounded"
            />
            <span className="text-sm">Paid Break</span>
          </label>
        </div>
      </div>

      {/* Weekend Work Section */}
      <div>
        <div className="mb-4">
          <label className="text-gray-700 text-sm font-medium">
            Weekend Work Available
          </label>
        </div>

        {/* Saturday */}
        <div className="flex items-center gap-4 mb-4">
          <div className="w-[150px]">
            <label className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={formData.weekend_work_sat}
                onChange={(e) => onChange("weekend_work_sat", e.target.checked)}
                className="rounded"
              />
              <span className="text-sm">Saturday</span>
            </label>
          </div>
          <select
            className="w-[185px] px-3 py-2 border border-gray-300 rounded-md"
            value={formData.weekend_work_sat_options}
            onChange={(e) =>
              onChange("weekend_work_sat_options", e.target.value)
            }
            disabled={!formData.weekend_work_sat}
          >
            <option value="">Select Option</option>
            <option value="regular">Regular</option>
            <option value="occasional">Occasional</option>
            <option value="overtime">Overtime Available</option>
          </select>
        </div>

        {/* Sunday */}
        <div className="flex items-center gap-4 mb-4">
          <div className="w-[150px]">
            <label className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={formData.weekend_work_sun}
                onChange={(e) => onChange("weekend_work_sun", e.target.checked)}
                className="rounded"
              />
              <span className="text-sm">Sunday</span>
            </label>
          </div>
          <select
            className="w-[185px] px-3 py-2 border border-gray-300 rounded-md"
            value={formData.weekend_work_sun_options}
            onChange={(e) =>
              onChange("weekend_work_sun_options", e.target.value)
            }
            disabled={!formData.weekend_work_sun}
          >
            <option value="">Select Option</option>
            <option value="regular">Regular</option>
            <option value="occasional">Occasional</option>
            <option value="overtime">Overtime Available</option>
          </select>
        </div>

        {/* Bank Holidays */}
        <div className="flex items-center gap-4">
          <div className="w-[150px]">
            <label className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={formData.weekend_work_bank_holiday}
                onChange={(e) =>
                  onChange("weekend_work_bank_holiday", e.target.checked)
                }
                className="rounded"
              />
              <span className="text-sm">Bank Holidays</span>
            </label>
          </div>
          <select
            className="w-[185px] px-3 py-2 border border-gray-300 rounded-md"
            value={formData.weekend_work_bank_holiday_options}
            onChange={(e) =>
              onChange("weekend_work_bank_holiday_options", e.target.value)
            }
            disabled={!formData.weekend_work_bank_holiday}
          >
            <option value="">Select Option</option>
            <option value="regular">Regular</option>
            <option value="occasional">Occasional</option>
            <option value="overtime">Overtime Available</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default TermsStep;
