import React, { useMemo } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import {
  Users,
  Briefcase,
  UserCheck,
  Clock,
  PoundSterling,
  Clock as TimeIcon,
  Star,
  CheckCircle,
} from "lucide-react";
import RecentApplicants from "./RecentApplicants";
import BillingInformation from "./BillingInformation";

const Dashboard = ({ jobs, jobseekers, onNavigate }) => {
  // Handler for viewing a specific jobseeker
  const handleViewJobseeker = (jobseekerId) => {
    onNavigate("jobseekerList", jobseekerId);
  };

  // Calculate statistics
  const stats = useMemo(() => {
    const totalJobs = jobs.length;
    const totalJobseekers = jobseekers.length;
    const averageApplicantsPerJob =
      totalJobs > 0 ? totalJobseekers / totalJobs : 0;
    const completedQuestionnaires = jobseekers.filter(
      (js) => js.questionnaireStatus === "completed",
    ).length;
    const questionnaireCompletionRate =
      totalJobseekers > 0
        ? (completedQuestionnaires / totalJobseekers) * 100
        : 0;
    const totalHired = jobseekers.filter(
      (js) => js.applicationStatus === "Hired",
    ).length;

    // Dummy data for additional stats
    // # TO DO to change with real values
    const averageCostPerHire = (Math.random() * 0.2 + 1).toFixed(2);
    const averageTimeToHire = (Math.random() * 4 + 1).toFixed(2);
    const averageApplicantRating = (Math.random() * (67 - 33) + 33).toFixed(0);

    return {
      totalJobs,
      totalJobseekers,
      averageApplicantsPerJob: averageApplicantsPerJob.toFixed(2),
      questionnaireCompletionRate: questionnaireCompletionRate.toFixed(2),
      averageCostPerHire,
      averageTimeToHire,
      averageApplicantRating,
      totalHired,
    };
  }, [jobs, jobseekers]);

  // Prepare data for application status chart
  const applicationStatusData = useMemo(() => {
    const statusCounts = jobseekers.reduce((acc, js) => {
      acc[js.applicationStatus] = (acc[js.applicationStatus] || 0) + 1;
      return acc;
    }, {});

    const colors = {
      Applied: "#aec6cf",
      Shortlisted: "#b2d8d8",
      "Interview Booked": "#cfcfc4",
      Interviewed: "#ffb347",
      Hired: "#c8e6c9",
    };

    const orderedStatuses = [
      "Applied",
      "Shortlisted",
      "Interview Booked",
      "Interviewed",
      "Hired",
    ];

    return orderedStatuses.map((status) => ({
      status,
      count: statusCounts[status] || 0,
      fill: colors[status] || "#8884d8",
    }));
  }, [jobseekers]);

  // Prepare data for questionnaire status pie chart
  const questionnaireStatusData = useMemo(() => {
    const statusCounts = jobseekers.reduce((acc, js) => {
      const status = js.questionnaireStatus || "Not Started";
      acc[status] = (acc[status] || 0) + 1;
      return acc;
    }, {});

    return Object.entries(statusCounts).map(([status, value]) => ({
      name: status.replace(/_/g, " ").replace(/\b\w/g, (l) => l.toUpperCase()),
      value,
    }));
  }, [jobseekers]);

  // Colors for pie chart
  const COLORS = ["#8884d8", "#82ca9d", "#ffc658", "#ff7300", "#0088FE"];

  return (
    <div className="p-4 bg-gradient-to-br from-blue-50 to-indigo-50 min-h-screen lg:p-8">
      <h2 className="text-3xl font-bold mb-8 text-indigo-800">Dashboard</h2>

      {/* Stats Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        <StatCard
          title="Total Jobs"
          value={stats.totalJobs}
          icon={<Briefcase />}
          onClick={() => onNavigate("jobListing")}
          color="bg-blue-500"
        />
        <StatCard
          title="Total Jobseekers"
          value={stats.totalJobseekers}
          icon={<Users />}
          onClick={() => onNavigate("jobseekerList")}
          color="bg-green-500"
        />
        <StatCard
          title="Avg. Applicants per Job"
          value={stats.averageApplicantsPerJob}
          icon={<UserCheck />}
          color="bg-yellow-500"
        />
        <StatCard
          title="Questionnaire Completion Rate"
          value={`${stats.questionnaireCompletionRate}%`}
          icon={<Clock />}
          color="bg-purple-500"
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        <StatCard
          title="Avg. Cost per Hire"
          value={`£${stats.averageCostPerHire}`}
          icon={<PoundSterling />}
          color="bg-pink-500"
        />
        <StatCard
          title="Avg. Time to Hire (days)"
          value={stats.averageTimeToHire}
          icon={<TimeIcon />}
          color="bg-indigo-500"
        />
        <StatCard
          title="Avg. Applicant Rating"
          value={stats.averageApplicantRating}
          icon={<Star />}
          color="bg-green-400"
        />
        <StatCard
          title="Total Hired"
          value={stats.totalHired}
          icon={<CheckCircle />}
          color="bg-blue-500"
        />
      </div>

      {/* Charts */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Application Status Chart */}
        <ChartCard title="Application Status Distribution">
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={applicationStatusData}>
              <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
              <XAxis dataKey="status" tick={{ fill: "#4a5568" }} />
              <YAxis tick={{ fill: "#4a5568" }} />
              <Tooltip
                contentStyle={{ backgroundColor: "#fff", borderRadius: "8px" }}
              />
              <Legend wrapperStyle={{ paddingTop: "20px" }} />
              <Bar
                dataKey="count"
                isAnimationActive={true}
                animationDuration={1000}
              >
                {applicationStatusData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.fill} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </ChartCard>

        {/* Recent Applicants */}
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <RecentApplicants
            jobseekers={jobseekers}
            onViewJobseeker={handleViewJobseeker}
          />
        </div>

        {/* Questionnaire Status Pie Chart */}
        <ChartCard title="Questionnaire Status">
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={questionnaireStatusData}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
                label={({ name, percent }) =>
                  `${name} ${(percent * 100).toFixed(0)}%`
                }
              >
                {questionnaireStatusData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip
                contentStyle={{ backgroundColor: "#fff", borderRadius: "8px" }}
              />
            </PieChart>
          </ResponsiveContainer>
        </ChartCard>

        {/* Billing Information */}
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <BillingInformation />
        </div>
      </div>
    </div>
  );
};

const StatCard = ({ title, value, icon, onClick, color }) => (
  <div
    className={`bg-white p-6 rounded-lg shadow-lg flex items-center transition-all duration-300 ease-in-out transform hover:scale-105 ${onClick ? "cursor-pointer" : ""}`}
    onClick={onClick}
  >
    <div className={`mr-4 ${color} text-white p-3 rounded-full`}>{icon}</div>
    <div>
      <h3 className="text-2xl font-bold text-gray-800">{value}</h3>
      <p className="text-sm text-gray-600">{title}</p>
    </div>
  </div>
);

// ChartCard component for consistent chart styling
const ChartCard = ({ title, children }) => (
  <div className="bg-white p-6 rounded-lg shadow-lg">
    <h3 className="text-xl font-semibold mb-4 text-gray-800">{title}</h3>
    {children}
  </div>
);

export default Dashboard;
