import React, { useState, useCallback, useEffect } from "react";
import {
  ChevronDown,
  ChevronUp,
  Trash2,
  PlusCircle,
  Save,
  Brain,
} from "lucide-react";
import QuestionGenerator from "./QuestionGenerator";

import {
  AnswerTypes,
  DifficultyLevels,
  ImportanceLevels,
  QuestionTypes,
  ConditionTypes,
} from "./constants";

import {
  generateQuestionsWithAI,
  jobQuestionnaire,
  saveQuestion,
} from "./utils";
import { Oval } from "react-loader-spinner";

const QuestionnaireBuilder = ({ selectedJob }) => {
  const [allQuestions, setAllQuestions] = useState([]);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [expandedQuestions, setExpandedQuestions] = useState([]);
  const [displayLoader, setDisplayLoader] = useState(false);

  useEffect(() => {
    // Filter questions when selectedJob changes
    const filtered = allQuestions.filter((q) => q.jobId === selectedJob);
    setFilteredQuestions(filtered);
  }, [selectedJob, allQuestions]);

  const ConditionValueInput = ({ conditionType, value, onChange }) => {
    switch (conditionType) {
      case ConditionTypes.YES_NO:
        return (
          <select
            value={value}
            onChange={(e) => onChange(e.target.value)}
            className="w-full p-2 border rounded"
          >
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        );
      case ConditionTypes.KEYWORD:
        return (
          <div>
            <select
              value={value.matchType}
              onChange={(e) =>
                onChange({ ...value, matchType: e.target.value })
              }
              className="w-full p-2 border rounded mb-2"
            >
              <option value="matched">Matched</option>
              <option value="not_matched">Not Matched</option>
            </select>
            <input
              type="text"
              value={value.keywords}
              onChange={(e) => onChange({ ...value, keywords: e.target.value })}
              placeholder="Enter keywords (comma-separated)"
              className="w-full p-2 border rounded"
            />
          </div>
        );
      case ConditionTypes.RATING:
        return (
          <div>
            <select
              value={value.type}
              onChange={(e) => onChange({ ...value, type: e.target.value })}
              className="w-full p-2 border rounded mb-2"
            >
              <option value="lessThan">Less than</option>
              <option value="moreThan">More than</option>
              <option value="exactly">Exactly</option>
            </select>
            <input
              type="number"
              min="1"
              max="10"
              value={value.value || 5}
              onChange={(e) =>
                onChange({ ...value, value: parseInt(e.target.value) })
              }
              className="w-full p-2 border rounded"
            />
          </div>
        );
      case ConditionTypes.CONFIDENCE:
      case ConditionTypes.COMPLETENESS:
        return (
          <div>
            <input
              type="range"
              min="0"
              max="100"
              value={value}
              onChange={(e) => onChange(parseInt(e.target.value))}
              className="w-full"
            />
            <span>{value}%</span>
          </div>
        );
      case ConditionTypes.LENGTH:
        return (
          <div>
            <select
              value={value.type}
              onChange={(e) => onChange({ ...value, type: e.target.value })}
              className="w-full p-2 border rounded mb-2"
            >
              <option value="lessThan">Less than</option>
              <option value="moreThan">More than</option>
            </select>
            <input
              type="number"
              min="0"
              value={value.value || 0}
              onChange={(e) =>
                onChange({ ...value, value: parseInt(e.target.value) })
              }
              className="w-full p-2 border rounded"
            />
          </div>
        );
      default:
        return null;
    }
  };

  const maintainInputFocus = (activeElement) => {
    // Check if the active element is a valid input
    if (activeElement && typeof activeElement.focus === "function") {
      // Restore the focus after the state update
      setTimeout(() => {
        activeElement.focus();
      }, 0);
    }
  };

  const updateQuestion = (id, field, value, target) => {
    const activeElement = target || document.activeElement;
    setFilteredQuestions((prevQuestions) => {
      const updateRecursive = (questions) => {
        return questions.map((q) => {
          if (q._id === id) {
            let updatedQuestion = { ...q, [field]: value };
            if (field === "answer_type") {
              if (
                [
                  AnswerTypes.MULTIPLE_CHOICE,
                  AnswerTypes.MULTIPLE_CHOICE_MULTI_ANSWER,
                  AnswerTypes.RANKED,
                  AnswerTypes.MULTIPLE_CHOICE_OTHER,
                ].includes(value)
              ) {
                // Initialize with one option if there are no existing options
                updatedQuestion.options = updatedQuestion.options || [
                  { option: "", score: 1 },
                ];
                // Ensure answer_scoring is in the correct format
                updatedQuestion.answer_scoring = updatedQuestion.options.map(
                  (option) => ({
                    option: option.option || "",
                    score: option.score || 0,
                  }),
                );
              } else {
                delete updatedQuestion.options;
                delete updatedQuestion.answer_scoring;
              }
            } else if (field === "checklist") {
              let checklist =
                typeof q.checklist === "string" ? {} : (q.checklist ?? {}); // If q.checklist is a string, create an empty object
              checklist.name = value;

              updatedQuestion.checklist = checklist;
            }
            return updatedQuestion;
          }
          if (q.follow_up_questions) {
            return {
              ...q,
              follow_up_questions: updateRecursive(q.follow_up_questions),
            };
          }
          return q;
        });
      };
      const updatedQuestions = updateRecursive(prevQuestions);
      setAllQuestions((prevAll) =>
        prevAll.map(
          (q) => updatedQuestions.find((uq) => uq._id === q._id) || q,
        ),
      );
      return updatedQuestions;
    });
    maintainInputFocus(activeElement);
  };

  const updateMultipleChoiceOption = (questionId, index, field, value) => {
    setFilteredQuestions((prevQuestions) => {
      return prevQuestions.map((q) => {
        if (q.id === questionId) {
          const updatedAnswerScoring = [...q.answer_scoring];
          updatedAnswerScoring[index] = {
            ...updatedAnswerScoring[index],
            [field]: value,
          };
          return { ...q, answer_scoring: updatedAnswerScoring };
        }
        return q;
      });
    });
  };

  const addMultipleChoiceOption = (questionId) => {
    setFilteredQuestions((prevQuestions) => {
      return prevQuestions.map((q) => {
        if (q.id === questionId) {
          const newOption = { option: "", score: 0 };
          const updatedAnswerScoring = [...(q.answer_scoring || []), newOption];
          return { ...q, answer_scoring: updatedAnswerScoring };
        }
        return q;
      });
    });
  };

  const removeMultipleChoiceOption = (questionId, index) => {
    setFilteredQuestions((prevQuestions) => {
      return prevQuestions.map((q) => {
        if (q.id === questionId && q.options.length > 1) {
          const updatedOptions = q.options.filter((_, i) => i !== index);
          const updatedAnswerScoring = updatedOptions.map((option) => ({
            option: option.text,
            score: option.score,
          }));
          return {
            ...q,
            options: updatedOptions,
            answer_scoring: updatedAnswerScoring,
          };
        }
        return q;
      });
    });
  };

  const addFollowUpQuestion = (parentId) => {
    setFilteredQuestions((prevQuestions) => {
      const addFollowUpRecursive = (questions) => {
        return questions.map((q) => {
          if (q.id === parentId) {
            const newFollowUp = {
              id: Date.now().toString(),
              condition: ConditionTypes.YES_NO,
              condition_value: "yes",
              question: {
                id: (Date.now() + 1).toString(),
                question_text: "New follow-up question",
                importance: ImportanceLevels.MEDIUM,
                answer_type: AnswerTypes.OPEN_ENDED,
                difficulty_level: DifficultyLevels.MEDIUM,
                evaluation_criteria: [],
                context: "",
                tags: [],
                follow_up_questions: [],
                model_answer: "",
                DATE: "Date",
                TIME: "Time",
                DATE_TIME: "Date and Time",
                NUMERIC: "Numeric",
                EMAIL: "Email",
                PHONE: "Phone",
                URL: "URL",
                IMAGE: "Image",
                jobId: selectedJob,
              },
            };
            return {
              ...q,
              follow_up_questions: [
                ...(q.follow_up_questions || []),
                newFollowUp,
              ],
            };
          }
          if (q.follow_up_questions) {
            return {
              ...q,
              follow_up_questions: addFollowUpRecursive(q.follow_up_questions),
            };
          }
          return q;
        });
      };
      const updatedQuestions = addFollowUpRecursive(prevQuestions);
      setAllQuestions((prevAll) => addFollowUpRecursive(prevAll));
      return updatedQuestions;
    });

    // Ensure the question is expanded after adding a follow-up
    setExpandedQuestions((prev) => {
      if (!prev.includes(parentId)) {
        return [...prev, parentId];
      }
      return prev;
    });
  };

  const updateFollowUpCondition = (parentId, followUpId, field, value) => {
    setFilteredQuestions((prevQuestions) => {
      const updateRecursive = (questions) => {
        return questions.map((q) => {
          if (q.id === parentId) {
            return {
              ...q,
              follow_up_questions: q.follow_up_questions.map((fu) => {
                if (fu.id === followUpId) {
                  if (field === "condition") {
                    // Reset condition_value when condition type changes
                    let newConditionValue;
                    switch (value) {
                      case ConditionTypes.YES_NO:
                        newConditionValue = "yes";
                        break;
                      case ConditionTypes.KEYWORD:
                        newConditionValue = {
                          matchType: "matched",
                          keywords: "",
                        };
                        break;
                      case ConditionTypes.RATING:
                        newConditionValue = { type: "lessThan", value: 5 };
                        break;
                      case ConditionTypes.LENGTH:
                        newConditionValue = { type: "lessThan", value: 0 };
                        break;
                      case ConditionTypes.CONFIDENCE:
                      case ConditionTypes.COMPLETENESS:
                        newConditionValue = 50;
                        break;
                      case ConditionTypes.RANKED: // New
                      case ConditionTypes.MULTIPLE_CHOICE_OTHER: // New
                        newConditionValue = { option: "", score: 0 };
                        break;
                      default:
                        newConditionValue = "";
                    }
                    return {
                      ...fu,
                      [field]: value,
                      condition_value: newConditionValue,
                    };
                  }
                  return { ...fu, [field]: value };
                }
                return fu;
              }),
            };
          }
          if (q.follow_up_questions) {
            return {
              ...q,
              follow_up_questions: updateRecursive(q.follow_up_questions),
            };
          }
          return q;
        });
      };
      const updatedQuestions = updateRecursive(prevQuestions);
      setAllQuestions((prevAll) => updateRecursive(prevAll));
      return updatedQuestions;
    });
  };

  const removeQuestion = (id) => {
    setFilteredQuestions((prevQuestions) => {
      const removeRecursive = (questions) => {
        return questions
          .map((q) => {
            if (q.follow_up_questions) {
              // Remove the follow-up question if it matches the id
              const updatedFollowUps = q.follow_up_questions.filter(
                (fu) => fu.question.id !== id,
              );

              // If a follow-up was removed, return the question with updated follow-ups
              if (updatedFollowUps.length !== q.follow_up_questions.length) {
                return { ...q, follow_up_questions: updatedFollowUps };
              }

              // Otherwise, continue searching deeper
              return {
                ...q,
                follow_up_questions: removeRecursive(q.follow_up_questions),
              };
            }
            return q;
          })
          .filter((q) => q.id !== id); // Remove the main question if it matches the id
      };

      const updatedQuestions = removeRecursive(prevQuestions);
      setAllQuestions((prevAll) => removeRecursive(prevAll));
      return updatedQuestions;
    });
  };

  const handleQuestionsGenerated = useCallback(
    async (generatedQuestions) => {
      //console.log("Generated Questions:", generatedQuestions);
      const db_questions = await jobQuestionnaire(selectedJob);
      generatedQuestions = [...db_questions, ...generatedQuestions];
      setAllQuestions(generatedQuestions);
      const filtered = generatedQuestions.filter(
        (q) => q.jobId === selectedJob,
      );
      setFilteredQuestions(filtered);
    },
    [selectedJob],
  );

  const toggleExpandQuestion = (id) => {
    setExpandedQuestions((prevState) => {
      if (prevState.includes(id)) {
        return prevState.filter((questionId) => questionId !== id);
      } else {
        return [...prevState, id];
      }
    });
  };

  const generateQuestions = async () => {
    setDisplayLoader(true);
    const questionnaire = await generateQuestionsWithAI(selectedJob);
    setFilteredQuestions(questionnaire);
    setDisplayLoader(false);
  };

  const handleSave = () => {
    console.log("Simulating API call to save questions...");

    // Create a deep copy of the filtered questions to avoid modifying the state directly
    const questionsToSave = JSON.parse(JSON.stringify(filteredQuestions));

    // Ensure all Yes/No questions have the latest answer_scoring
    const updatedQuestions = questionsToSave.map((question) => {
      if (question.answer_type === AnswerTypes.YES_NO) {
        // If answer_scoring doesn't exist, initialize it with default values
        if (!question.answer_scoring) {
          question.answer_scoring = { Yes: 1, No: 0 };
        }
        // Ensure the values are numbers and rounded to 2 decimal places
        question.answer_scoring.Yes = parseFloat(
          question.answer_scoring.Yes.toFixed(2),
        );
        question.answer_scoring.No = parseFloat(
          question.answer_scoring.No.toFixed(2),
        );
      }
      return question;
    });

    const questionsJSON = {
      jobId: selectedJob,
      questions: updatedQuestions,
    };
    saveQuestion(questionsJSON);
  };

  const getScoreColor = (score) => {
    // Ensure the score is between 0 and 1
    const clampedScore = Math.max(0, Math.min(1, score));

    // Calculate red and green components
    const red = Math.round(255 * (1 - clampedScore));
    const green = Math.round(255 * clampedScore);

    return `rgb(${red}, ${green}, 0)`;
  };

  const countFollowUpQuestions = (question) => {
    if (
      !question.follow_up_questions ||
      question.follow_up_questions.length === 0
    )
      return 0;
    return question.follow_up_questions.reduce(
      (count, followUp) =>
        count + 1 + countFollowUpQuestions(followUp.question),
      0,
    );
  };

  const QuestionTile = ({ question, depth = 0, index }) => {
    const isExpanded = expandedQuestions.includes(question._id);

    const getDifficultyColor = (level) => {
      switch (level) {
        case "Easy":
          return "bg-green-100 border-green-500";
        case "Medium":
          return "bg-yellow-100 border-yellow-500";
        case "Hard":
          return "bg-red-100 border-red-500";
        default:
          return "";
      }
    };

    const getImportanceColor = (level) => {
      switch (level) {
        case "Low":
          return "bg-blue-100 border-blue-300";
        case "Medium":
          return "bg-blue-200 border-blue-500";
        case "High":
          return "bg-purple-200 border-purple-500";
        case "Critical":
          return "bg-red-200 border-red-500";
        default:
          return "";
      }
    };

    const followUpCount = countFollowUpQuestions(question);

    return (
      <div
        key={index}
        className={`mb-2 ${depth > 0 ? "mt-6 ml-0 relative" : ""} ${index % 2 === 0 ? "bg-gray-100" : "bg-white"}`}
      >
        <div
          className={`rounded-lg shadow-md p-4 ${depth > 0 ? "bg-blue-50 border-l-4 border-blue-500" : "bg-white"}`}
        >
          <div className="flex items-center mb-2">
            <h3
              className="text-lg font-semibold flex-grow cursor-pointer"
              onClick={() => toggleExpandQuestion(question._id)}
            >
              {!isExpanded && (
                <>
                  {`${question.question_text}`}
                  {followUpCount > 0 && (
                    <span className="ml-2 bg-green-500 text-white text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full">
                      {followUpCount}
                    </span>
                  )}
                </>
              )}
            </h3>
            <button
              onClick={() => toggleExpandQuestion(question._id)}
              className="mr-2 px-2 py-1 bg-blue-500 text-white rounded flex items-center"
              title={isExpanded ? "Collapse" : "Expand"}
            >
              {isExpanded ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation(); // Stop event propagation
                addFollowUpQuestion(question.id);
              }}
              className="mr-2 px-2 py-1 bg-green-500 text-white rounded flex items-center"
              title="Add follow-up question"
            >
              <PlusCircle size={16} className="mr-1" /> Follow-up
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                console.log(
                  "Delete button clicked for question id:",
                  question.id,
                ); // Log the id of the question to be deleted
                removeQuestion(question.id);
              }}
              className="text-red-500 hover:text-red-700"
              title="Remove question"
            >
              <Trash2 size={20} />
            </button>
          </div>
          {isExpanded && (
            <>
              <textarea
                value={question.question_text}
                onChange={(e) =>
                  updateQuestion(
                    question._id,
                    "question_text",
                    e.target.value,
                    e.target,
                  )
                }
                placeholder="Enter question text"
                className="w-full p-2 border rounded mb-2 resize-y"
                rows="3"
              />
              <div className="grid grid-cols-2 gap-4 mb-2">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Job Role
                  </label>
                  <input
                    type="text"
                    value={question.job_role}
                    onChange={(e) =>
                      updateQuestion(
                        question._id,
                        "job_role",
                        e.target.value,
                        e.target,
                      )
                    }
                    className="w-full p-2 border rounded"
                    placeholder="Enter job role"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Category
                  </label>
                  <input
                    type="text"
                    value={question.category}
                    onChange={(e) =>
                      updateQuestion(
                        question._id,
                        "category",
                        e.target.value,
                        e.target,
                      )
                    }
                    className="w-full p-2 border rounded"
                    placeholder="Enter category"
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4 mb-2">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Difficulty Level
                  </label>
                  <select
                    value={question.difficulty_level}
                    onChange={(e) =>
                      updateQuestion(
                        question._id,
                        "difficulty_level",
                        e.target.value,
                        e.target,
                      )
                    }
                    className={`w-full p-2 border rounded ${getDifficultyColor(question.difficulty_level)}`}
                  >
                    {Object.entries(DifficultyLevels).map(([key, value]) => (
                      <option key={key} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Importance
                  </label>
                  <select
                    value={question.importance}
                    onChange={(e) =>
                      updateQuestion(
                        question._id,
                        "importance",
                        e.target.value,
                        e.target,
                      )
                    }
                    className={`w-full p-2 border rounded ${getImportanceColor(question.importance)}`}
                  >
                    {Object.entries(ImportanceLevels).map(([key, value]) => (
                      <option key={key} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4 mb-2">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Answer Type
                  </label>
                  <select
                    value={question.answer_type}
                    onChange={(e) =>
                      updateQuestion(
                        question._id,
                        "answer_type",
                        e.target.value,
                        e.target,
                      )
                    }
                    className="w-full p-2 border rounded"
                  >
                    {Object.entries(AnswerTypes).map(([key, value]) => (
                      <option key={key} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Question Type
                  </label>
                  <select
                    value={question.question_type}
                    onChange={(e) =>
                      updateQuestion(
                        question._id,
                        "question_type",
                        e.target.value,
                        e.target,
                      )
                    }
                    className="w-full p-2 border rounded"
                  >
                    {Object.entries(QuestionTypes).map(([key, value]) => (
                      <option key={key} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {(question.answer_type === AnswerTypes.MULTIPLE_CHOICE ||
                question.answer_type ===
                AnswerTypes.MULTIPLE_CHOICE_MULTI_ANSWER) && (
                  <div className="mb-2 ml-3 bg-yellow-50 p-3 shadow">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Multiple Choice Options
                    </label>
                    {Array.isArray(question.answer_scoring) &&
                      question.answer_scoring.map((option, index) => (
                        <div key={index} className="flex items-center mb-2">
                          <input
                            type="text"
                            value={option.option}
                            onChange={(e) =>
                              updateMultipleChoiceOption(
                                question.id,
                                index,
                                "option",
                                e.target.value,
                              )
                            }
                            className="flex-grow p-2 border rounded mr-2"
                            placeholder={`Option ${index + 1}`}
                          />
                          <input
                            type="number"
                            value={option.score}
                            onChange={(e) => {
                              const value = parseFloat(e.target.value);
                              if (!isNaN(value) && value >= 0 && value <= 1) {
                                updateMultipleChoiceOption(
                                  question.id,
                                  index,
                                  "score",
                                  parseFloat(value.toFixed(2)),
                                );
                              }
                            }}
                            step="0.01"
                            min="0"
                            max="1"
                            className="w-20 p-2 border rounded mr-2"
                            placeholder="Score"
                            style={{ borderColor: getScoreColor(option.score) }}
                          />
                          <button
                            onClick={() =>
                              removeMultipleChoiceOption(question.id, index)
                            }
                            className="text-red-500 hover:text-red-700"
                            title="Remove option"
                          >
                            <Trash2 size={20} />
                          </button>
                        </div>
                      ))}
                    <button
                      onClick={() => addMultipleChoiceOption(question.id)}
                      className="mt-2 px-2 py-1 bg-blue-500 text-white rounded flex items-center"
                    >
                      <PlusCircle size={16} className="mr-1" /> Add Option
                    </button>
                  </div>
                )}
              {(question.answer_type === AnswerTypes.RANKED ||
                question.answer_type === AnswerTypes.MULTIPLE_CHOICE_OTHER) && (
                  <div className="mb-2 ml-3 bg-yellow-50 p-3 shadow">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      {question.answer_type === AnswerTypes.RANKED
                        ? "Ranked Options"
                        : "Multiple Choice Options (Other)"}
                    </label>
                    {question.answer_scoring &&
                      question.answer_scoring.map((option, index) => (
                        <div key={index} className="flex items-center mb-2">
                          <input
                            type="text"
                            value={option.option}
                            onChange={(e) =>
                              updateMultipleChoiceOption(
                                question.id,
                                index,
                                "option",
                                e.target.value,
                              )
                            }
                            className="flex-grow p-2 border rounded mr-2"
                            placeholder={`Option ${index + 1}`}
                          />
                          <input
                            type="number"
                            value={option.score}
                            onChange={(e) => {
                              const value = parseFloat(e.target.value);
                              if (!isNaN(value) && value >= 0 && value <= 1) {
                                updateMultipleChoiceOption(
                                  question.id,
                                  index,
                                  "score",
                                  parseFloat(value.toFixed(2)),
                                );
                              }
                            }}
                            step="0.01"
                            min="0"
                            max="1"
                            className="w-20 p-2 border rounded mr-2"
                            placeholder="Score"
                            style={{ borderColor: getScoreColor(option.score) }}
                          />
                          <button
                            onClick={() =>
                              removeMultipleChoiceOption(question.id, index)
                            }
                            className="text-red-500 hover:text-red-700"
                            title="Remove option"
                          >
                            <Trash2 size={20} />
                          </button>
                        </div>
                      ))}
                    <button
                      onClick={() => addMultipleChoiceOption(question.id)}
                      className="mt-2 px-2 py-1 bg-blue-500 text-white rounded flex items-center"
                    >
                      <PlusCircle size={16} className="mr-1" /> Add Option
                    </button>
                  </div>
                )}
              {question.answer_type === AnswerTypes.FILE && (
                <div className="mb-2 ml-3 bg-yellow-50 p-3 shadow">
                  <label className="block text-sm font-medium text-gray-700">
                    File Upload Settings
                  </label>
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-xs text-gray-500">
                        Allowed File Types
                      </label>
                      <input
                        type="text"
                        value={question.allowedFileTypes || ""}
                        onChange={(e) =>
                          updateQuestion(
                            question._id,
                            "allowedFileTypes",
                            e.target.value,
                            e.target,
                          )
                        }
                        className="w-full p-2 border rounded"
                        placeholder="e.g., .pdf,.doc,.jpg"
                      />
                    </div>
                    <div>
                      <label className="block text-xs text-gray-500">
                        Max File Size (MB)
                      </label>
                      <input
                        type="number"
                        value={question.maxFileSize || ""}
                        onChange={(e) =>
                          updateQuestion(
                            question._id,
                            "maxFileSize",
                            e.target.value,
                            e.target,
                          )
                        }
                        className="w-full p-2 border rounded"
                        placeholder="e.g., 5"
                      />
                    </div>
                  </div>
                </div>
              )}
              {question.answer_type === AnswerTypes.DATE && (
                <div className="mb-2 ml-3 bg-yellow-50 p-3 shadow">
                  <label className="block text-sm font-medium text-gray-700">
                    Date Settings
                  </label>
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-xs text-gray-500">
                        Min Date
                      </label>
                      <input
                        type="date"
                        value={question.minDate || ""}
                        onChange={(e) =>
                          updateQuestion(
                            question._id,
                            "minDate",
                            e.target.value,
                            e.target,
                          )
                        }
                        className="w-full p-2 border rounded"
                      />
                    </div>
                    <div>
                      <label className="block text-xs text-gray-500">
                        Max Date
                      </label>
                      <input
                        type="date"
                        value={question.maxDate || ""}
                        onChange={(e) =>
                          updateQuestion(
                            question._id,
                            "maxDate",
                            e.target.value,
                            e.target,
                          )
                        }
                        className="w-full p-2 border rounded"
                      />
                    </div>
                  </div>
                </div>
              )}
              {question.answer_type === AnswerTypes.TIME && (
                <div className="mb-2 ml-3 bg-yellow-50 p-3 shadow">
                  <label className="block text-sm font-medium text-gray-700">
                    Time Settings
                  </label>
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-xs text-gray-500">
                        Min Time
                      </label>
                      <input
                        type="time"
                        value={question.minTime || ""}
                        onChange={(e) =>
                          updateQuestion(
                            question._id,
                            "minTime",
                            e.target.value,
                            e.target,
                          )
                        }
                        className="w-full p-2 border rounded"
                      />
                    </div>
                    <div>
                      <label className="block text-xs text-gray-500">
                        Max Time
                      </label>
                      <input
                        type="time"
                        value={question.maxTime || ""}
                        onChange={(e) =>
                          updateQuestion(
                            question._id,
                            "maxTime",
                            e.target.value,
                            e.target,
                          )
                        }
                        className="w-full p-2 border rounded"
                      />
                    </div>
                  </div>
                </div>
              )}
              {question.answer_type === AnswerTypes.DATE_TIME && (
                <div className="mb-2 ml-3 bg-yellow-50 p-3 shadow">
                  <label className="block text-sm font-medium text-gray-700">
                    Date and Time Settings
                  </label>
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-xs text-gray-500">
                        Min Date and Time
                      </label>
                      <input
                        type="datetime-local"
                        value={question.minDateTime || ""}
                        onChange={(e) =>
                          updateQuestion(
                            question._id,
                            "minDateTime",
                            e.target.value,
                            e.target,
                          )
                        }
                        className="w-full p-2 border rounded"
                      />
                    </div>
                    <div>
                      <label className="block text-xs text-gray-500">
                        Max Date and Time
                      </label>
                      <input
                        type="datetime-local"
                        value={question.maxDateTime || ""}
                        onChange={(e) =>
                          updateQuestion(
                            question._id,
                            "maxDateTime",
                            e.target.value,
                            e.target,
                          )
                        }
                        className="w-full p-2 border rounded"
                      />
                    </div>
                  </div>
                </div>
              )}
              {question.answer_type === AnswerTypes.NUMERIC && (
                <div className="mb-2 ml-3 bg-yellow-50 p-3 shadow">
                  <label className="block text-sm font-medium text-gray-700">
                    Numeric Settings
                  </label>
                  <div className="grid grid-cols-3 gap-4">
                    <div>
                      <label className="block text-xs text-gray-500">
                        Min Value
                      </label>
                      <input
                        type="number"
                        value={question.minValue || ""}
                        onChange={(e) =>
                          updateQuestion(
                            question._id,
                            "minValue",
                            e.target.value,
                            e.target,
                          )
                        }
                        className="w-full p-2 border rounded"
                      />
                    </div>
                    <div>
                      <label className="block text-xs text-gray-500">
                        Max Value
                      </label>
                      <input
                        type="number"
                        value={question.maxValue || ""}
                        onChange={(e) =>
                          updateQuestion(
                            question._id,
                            "maxValue",
                            e.target.value,
                            e.target,
                          )
                        }
                        className="w-full p-2 border rounded"
                      />
                    </div>
                    <div>
                      <label className="block text-xs text-gray-500">
                        Step
                      </label>
                      <input
                        type="number"
                        value={question.step || ""}
                        onChange={(e) =>
                          updateQuestion(
                            question._id,
                            "step",
                            e.target.value,
                            e.target,
                          )
                        }
                        className="w-full p-2 border rounded"
                        step="any"
                      />
                    </div>
                  </div>
                </div>
              )}
              {question.answer_type === AnswerTypes.EMAIL && (
                <div className="mb-2 ml-3 bg-yellow-50 p-3 shadow">
                  <label className="block text-sm font-medium text-gray-700">
                    Email Settings
                  </label>
                  <div>
                    <label className="block text-xs text-gray-500">
                      Allowed Domains (optional)
                    </label>
                    <input
                      type="text"
                      value={question.allowedDomains || ""}
                      onChange={(e) =>
                        updateQuestion(
                          question._id,
                          "allowedDomains",
                          e.target.value,
                          e.target,
                        )
                      }
                      className="w-full p-2 border rounded"
                      placeholder="e.g., gmail.com, yahoo.com (comma-separated)"
                    />
                  </div>
                </div>
              )}
              {question.answer_type === AnswerTypes.PHONE && (
                <div className="mb-2 ml-3 bg-yellow-50 p-3 shadow">
                  <label className="block text-sm font-medium text-gray-700">
                    Phone Settings
                  </label>
                  <div>
                    <label className="block text-xs text-gray-500">
                      Format
                    </label>
                    <input
                      type="text"
                      value={question.phoneFormat || ""}
                      onChange={(e) =>
                        updateQuestion(
                          question._id,
                          "phoneFormat",
                          e.target.value,
                          e.target,
                        )
                      }
                      className="w-full p-2 border rounded"
                      placeholder="e.g., XXX-XXX-XXXX"
                    />
                  </div>
                </div>
              )}
              {question.answer_type === AnswerTypes.URL && (
                <div className="mb-2 ml-3 bg-yellow-50 p-3 shadow">
                  <label className="block text-sm font-medium text-gray-700">
                    URL Settings
                  </label>
                  <div>
                    <label className="block text-xs text-gray-500">
                      Allowed Protocols
                    </label>
                    <input
                      type="text"
                      value={question.allowedProtocols || ""}
                      onChange={(e) =>
                        updateQuestion(
                          question._id,
                          "allowedProtocols",
                          e.target.value,
                          e.target,
                        )
                      }
                      className="w-full p-2 border rounded"
                      placeholder="e.g., http, https (comma-separated)"
                    />
                  </div>
                </div>
              )}
              {question.answer_type === AnswerTypes.IMAGE && (
                <div className="mb-2 ml-3 bg-yellow-50 p-3 shadow">
                  <label className="block text-sm font-medium text-gray-700">
                    Image Upload Settings
                  </label>
                  <div className="grid grid-cols-2 gap-4 ml">
                    <div>
                      <label className="block text-xs text-gray-500">
                        Allowed Image Types
                      </label>
                      <input
                        type="text"
                        value={question.allowedImageTypes || ""}
                        onChange={(e) =>
                          updateQuestion(
                            question._id,
                            "allowedImageTypes",
                            e.target.value,
                            e.target,
                          )
                        }
                        className="w-full p-2 border rounded"
                        placeholder="e.g., .jpg,.png,.gif"
                      />
                    </div>
                    <div>
                      <label className="block text-xs text-gray-500">
                        Max Image Size (MB)
                      </label>
                      <input
                        type="number"
                        value={question.maxImageSize || ""}
                        onChange={(e) =>
                          updateQuestion(
                            question._id,
                            "maxImageSize",
                            e.target.value,
                            e.target,
                          )
                        }
                        className="w-full p-2 border rounded"
                        placeholder="e.g., 2"
                      />
                    </div>
                  </div>
                </div>
              )}
              {question.answer_type === AnswerTypes.YES_NO && (
                <div className="mb-2 ml-3 bg-yellow-50 p-3 shadow">
                  <label className="block text-sm font-medium text-gray-700">
                    Yes/No Answer Scoring
                  </label>
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-xs text-gray-500">
                        Yes Score
                      </label>
                      <input
                        type="number"
                        value={question.answer_scoring?.Yes || 1}
                        onChange={(e) => {
                          const value = parseFloat(e.target.value);
                          if (!isNaN(value) && value >= 0 && value <= 1) {
                            updateQuestion(
                              question._id,
                              "answer_scoring",
                              {
                                ...question.answer_scoring,
                                Yes: parseFloat(value.toFixed(2)),
                              },
                              e.target,
                            );
                          }
                        }}
                        step="0.01"
                        min="0"
                        max="1"
                        className="w-full p-2 border rounded"
                        style={{
                          borderColor: getScoreColor(
                            question.answer_scoring?.Yes || 1,
                          ),
                        }}
                      />
                    </div>
                    <div>
                      <label className="block text-xs text-gray-500">
                        No Score
                      </label>
                      <input
                        type="number"
                        value={question.answer_scoring?.No || 0}
                        onChange={(e) => {
                          const value = parseFloat(e.target.value);
                          if (!isNaN(value) && value >= 0 && value <= 1) {
                            updateQuestion(
                              question._id,
                              "answer_scoring",
                              {
                                ...question.answer_scoring,
                                No: parseFloat(value.toFixed(2)),
                              },
                              e.target,
                            );
                          }
                        }}
                        step="0.01"
                        min="0"
                        max="1"
                        className="w-full p-2 border rounded"
                        style={{
                          borderColor: getScoreColor(
                            question.answer_scoring?.No || 0,
                          ),
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="mb-2">
                <label className="block text-sm font-medium text-gray-700">
                  Evaluation Criteria
                </label>
                <textarea
                  value={question.evaluation_criteria.join(", ")}
                  onChange={(e) =>
                    updateQuestion(
                      question._id,
                      "evaluation_criteria",
                      e.target.value.split(",").map((item) => item.trim()),
                      e.target,
                    )
                  }
                  className="w-full p-2 border rounded resize-y"
                  rows="2"
                  placeholder="Enter evaluation criteria (comma-separated)"
                />
              </div>
              <div className="mb-2">
                <label className="block text-sm font-medium text-gray-700">
                  Company Explanation
                </label>
                <textarea
                  value={question.company_explanation || ""}
                  onChange={(e) =>
                    updateQuestion(
                      question._id,
                      "company_explanation",
                      e.target.value,
                      e.target,
                    )
                  }
                  className="w-full p-2 border rounded resize-y"
                  rows="2"
                  placeholder="Enter company explanation"
                />
              </div>
              <div className="mb-2">
                <label className="block text-sm font-medium text-gray-700">
                  Context
                </label>
                <textarea
                  value={question.context}
                  onChange={(e) =>
                    updateQuestion(
                      question._id,
                      "context",
                      e.target.value,
                      e.target,
                    )
                  }
                  className="w-full p-2 border rounded resize-y"
                  rows="2"
                  placeholder="Enter question context"
                />
              </div>
              <div className="mb-2">
                <label className="block text-sm font-medium text-gray-700">
                  Tags
                </label>
                <input
                  type="text"
                  value={question.tags.join(", ")}
                  onChange={(e) =>
                    updateQuestion(
                      question._id,
                      "tags",
                      e.target.value.split(",").map((item) => item.trim()),
                      e.target,
                    )
                  }
                  className="w-full p-2 border rounded"
                  placeholder="Enter tags (comma-separated)"
                />
              </div>
              <div className="mb-2">
                <label className="block text-sm font-medium text-gray-700">
                  Model Answer
                </label>
                <textarea
                  value={question.model_answer || ""}
                  onChange={(e) =>
                    updateQuestion(
                      question._id,
                      "model_answer",
                      e.target.value,
                      e.target,
                    )
                  }
                  className="w-full p-2 border rounded resize-y"
                  rows="4"
                  placeholder="Enter model answer"
                />
              </div>
              <div className="mb-2">
                <label className="block text-sm font-medium text-gray-700">
                  Checklist
                </label>
                <input
                  value={question?.checklist?.name || ""}
                  onChange={(e) =>
                    updateQuestion(
                      question._id,
                      "checklist",
                      e.target.value,
                      e.target,
                    )
                  }
                  className="w-full p-2 border rounded resize-y"
                  rows="4"
                  placeholder="Enter checklist name"
                />
              </div>
            </>
          )}
        </div>
        {isExpanded &&
          question.follow_up_questions &&
          question.follow_up_questions.map((followUp) => (
            <div
              key={followUp.id}
              className="mt-4 ml-8 p-4 bg-yellow-50 rounded-lg shadow"
            >
              <h4 className="font-semibold mb-2">Follow-up Condition</h4>
              <div className="grid grid-cols-2 gap-4 mb-2">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Condition Type
                  </label>
                  <select
                    value={followUp.condition}
                    onChange={(e) =>
                      updateFollowUpCondition(
                        question.id,
                        followUp.id,
                        "condition",
                        e.target.value,
                      )
                    }
                    className="w-full p-2 border rounded"
                  >
                    {Object.entries(ConditionTypes).map(([key, value]) => (
                      <option key={key} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Condition Value
                  </label>
                  <ConditionValueInput
                    conditionType={followUp.condition}
                    value={followUp.condition_value}
                    onChange={(value) =>
                      updateFollowUpCondition(
                        question.id,
                        followUp.id,
                        "condition_value",
                        value,
                      )
                    }
                  />
                </div>
              </div>
              {followUp.question && (
                <QuestionTile question={followUp.question} depth={depth + 1} />
              )}
            </div>
          ))}
      </div>
    );
  };

  return (
    <div className="p-6 lg:p-8">
      <h2 className="text-2xl font-bold mb-6">Jobseeker Question Builder</h2>
      <QuestionGenerator
        key={`QuestionGenerator1`}
        onQuestionsGenerated={handleQuestionsGenerated}
      />
      {filteredQuestions.map((question, index) => (
        <QuestionTile
          key={`${question._id}`}
          question={question}
          index={`${index}`}
        />
      ))}
      <button
        onClick={() => {
          const newQuestion = {
            _id: Date.now().toString(),
            jobId: selectedJob, // Ensure new questions are associated with the current job
            job_role: "",
            category: "",
            difficulty_level: DifficultyLevels.MEDIUM,
            importance: ImportanceLevels.MEDIUM,
            question_text: "New question",
            answer_type: AnswerTypes.OPEN_ENDED,
            evaluation_criteria: [],
            context: "",
            tags: [],
            follow_up_questions: [],
            model_answer: "",
            allowedFileTypes: "",
            maxFileSize: "",
            minDate: "",
            maxDate: "",
            minTime: "",
            maxTime: "",
            minDateTime: "",
            maxDateTime: "",
            minValue: "",
            maxValue: "",
            step: "",
            allowedDomains: "",
            phoneFormat: "",
            allowedProtocols: "",
            allowedImageTypes: "",
            maxImageSize: "",
            answer_scoring: { Yes: 1, No: 0 }, // Default scoring for Yes/No questions,
            company_explanation: "",
            question_type: "Assessment",
          };
          setAllQuestions((prev) => [...prev, newQuestion]);
          setFilteredQuestions((prev) => [...prev, newQuestion]);
        }}
        className="mt-4 px-4 py-2 text-white rounded hover:bg-opacity-90 flex items-center"
        style={{ backgroundColor: "#4c4def" }}
      >
        <PlusCircle size={20} className="mr-2" /> Add New Question
      </button>
      <button
        onClick={generateQuestions}
        className="mt-4 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 flex items-center"
      >
        <Brain size={20} className="mr-2" /> Generate Questions with AI &nbsp;
        {displayLoader && (
          <Oval
            visible={true}
            height="16"
            width="16"
            color="white"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        )}
      </button>
      <button
        onClick={handleSave}
        className="mt-4 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 flex items-center"
      >
        <Save size={20} className="mr-2" /> Save Questions
      </button>
    </div>
  );
};

export default QuestionnaireBuilder;
