import React, { useState, useEffect } from "react";
import { Check, ChevronRight, Copy } from "lucide-react";
import JobService from "./services/JobService";
import { toast } from "react-toastify";
import Card from "./components/Card";
import BasicDetailsStep from "./steps/BasicDetailsStep";
import TermsStep from "./steps/TermsStep";
import RequirementsStep from "./steps/RequirementsStep";
import JobDescriptionStep from "./steps/JobDescriptionStep";
import AboutStep from "./steps/AboutStep";
import PreviewStep from "./steps/PreviewStep";
//import ApiPosting from "./steps/PreviewStep";

const JobPostForm = ({ company }) => {
  const [currentStep, setCurrentStep] = useState("basic");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [previousJobs, setPreviousJobs] = useState([]);
  const [isLoadingJobs, setIsLoadingJobs] = useState(true);

  const [formData, setFormData] = useState({
    // Basic details
    number_of_hires: "",
    title: "",
    addressgeo: "",
    postcode: "",
    city: "",
    region: "",
    generic_job_detail: "",
    industry: "",
    job_refreneces: "",

    // Terms
    job_type: "",
    is_graduate_job: false,
    start_date: "",
    start_asap: false,
    no_start_date: false,
    duration_count: "",
    duration_category: "",
    duration_long_term: false,
    salary: "",
    is_salary_based_exp: false,
    show_pay_by: "",
    salary_min: "",
    salary_max: "",
    salarytype: "",
    break_duration_min: "",
    is_break_paid: false,
    weekend_work_sat: false,
    weekend_work_sat_options: "",
    weekend_work_sun: false,
    weekend_work_sun_options: "",
    weekend_work_bank_holiday: false,
    weekend_work_bank_holiday_options: "",
    hours: 8,
    hours_from: "09:00:00",
    hours_till: "17:00:00",
    weekend_work_other_options: "",

    // Requirements
    qualifications: [],
    preferred_qualifications: [],
    qualification_other: "",
    additional_requirment: "",
    preferred_skills: [],
    min_year_exp: "",
    education: "",
    remotetype: "",
    cv_required: true, // Default to true
    is_references_required: true, // Default to true
    references_required_options: "2", // Default to 2 references
    pre_screening_questions: false,

    // Job Description
    description: "",

    // About
    company: "",
    company_email: "",
    phone: "",
    company_website: "",
    company_slogan: "",
    company_overview_text: "",
    company_id: company._id,
    post_cvl: true,
  });

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        // Load previous jobs
        const jobs = await JobService.getPreviousJobs(company);
        setPreviousJobs(jobs);

        // Load company data and pre-fill form
        setFormData((prev) => ({
          ...prev,
          company: company.name,
          company_email: company.contactEmail,
          phone: company.phone,
          company_website: company.website,
          company_slogan: company.slogan,
          company_overview_text: company.overview,
          industry: company.industry,
        }));
      } catch (error) {
        console.error("Error loading initial data:", error);
        toast.error("Error loading data");
      } finally {
        setIsLoadingJobs(false);
      }
    };

    loadInitialData();
  }, []);

  // Updated job selection handler
  const handleJobSelection = (jobId) => {
    const selectedJob = previousJobs.find((job) => job.id === jobId);
    if (selectedJob) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        ...selectedJob,
        qualifications: selectedJob.qualifications || [],
        preferred_qualifications: selectedJob.preferred_qualifications || [],
        preferred_skills: selectedJob.preferred_skills || [],
      }));
      toast.success("Job details copied");
    }
  };

  const handleInputChange = (field, value) => {
    const timestamp = new Date().toLocaleDateString();
    let title = formData.title;
    let industry = formData.industry;
    let city = formData.city

    if (field === "industry") {
      industry = value;
    }

    let refernece = `${title}/${city}/${industry}-${timestamp}`;

    setFormData((prev) => ({
      ...prev,
      [field]: value,
      job_refreneces: refernece,
    }));
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      // First send to Veritone

      //console.log(formData);return false;
      await JobService.createJob(formData);
      toast.success("Job posted successfully!");
      // We may want to redirect to the job listing page or clear the form
      window.location.href = "/";
      // or
      //setFormData(formData);
    } catch (error) {
      console.error("Error posting job:", error);
      toast.error(error.message || "Error posting job");
    } finally {
      setIsSubmitting(false);
    }
  };

  const steps = [
    { id: "basic", label: "Basic Details", icon: "1" },
    { id: "terms", label: "Terms & Conditions", icon: "2" },
    { id: "requirements", label: "Requirements", icon: "3" },
    { id: "jobDescription", label: "Job Description", icon: "4" },
    { id: "about", label: "About Company", icon: "5" },
    //{ id: "apiPosting", label: "API Posting", icon: "6" },
    { id: "preview", label: "Preview & Submit", icon: "6" },
  ];

  const getCurrentStepIndex = () =>
    steps.findIndex((s) => s.id === currentStep);

  // Updated validation functions
  const validateBasicDetails = () => {
    const {
      title = "",
      number_of_hires = "",
      addressgeo = "",
      postcode = "",
      city = "",
      region = "",
      //generic_job_detail = "",
      industry = "",
      job_refreneces = "",
    } = formData;

    return (
      title.trim() !== "" &&
      number_of_hires !== "" &&
      addressgeo.trim() !== "" &&
      postcode.trim() !== "" &&
      city.trim() !== "" &&
      //region.trim() !== "" &&
      //generic_job_detail.trim() !== "" &&
      industry.trim() !== "" &&
      job_refreneces.trim() !== ""
    );
  };

  const validateTerms = () => {
    const {
      job_type = "",
      start_date = "",
      start_asap = false,
      no_start_date = false,
      salary = "",
      salarytype = "",
      hours = "",
      hours_from = "",
      hours_till = "",
    } = formData;

    return (
      job_type !== "" &&
      (start_asap || no_start_date || start_date !== "") &&
      salary !== "" &&
      salarytype !== "" &&
      hours !== "" &&
      hours_from !== "" &&
      hours_till !== ""
    );
  };

  // Updated validateRequirements function
  const validateRequirements = () => {
    const { qualifications = [] } = formData;
    return qualifications.length > 0;
  };

  // Updated validateJobDescription function
  const validateJobDescription = () => {
    const description = formData.description || "";
    return description.trim().length >= 50;
  };

  const validateAboutCompany = () => {
    const {
      company = "",
      company_email = "",
      phone = "",
      company_website = "",
      //company_overview_text = "",
    } = formData;
    return (
      company.trim() !== "" &&
      company_email.trim() !== "" &&
      phone.trim() !== ""
      //company_website.trim() !== ""
      //company_overview_text.trim() !== ""
    );
  };

  const validatePreview = () => currentStep === "preview";

  const validationFunctions = {
    basic: validateBasicDetails,
    terms: validateTerms,
    requirements: validateRequirements,
    jobDescription: validateJobDescription,
    about: validateAboutCompany,
    preview: validatePreview,
    //apiPosting: ApiPosting,
  };

  const renderStep = () => {
    switch (currentStep) {
      case "basic":
        return (
          <BasicDetailsStep formData={formData} onChange={handleInputChange} />
        );
      case "terms":
        return <TermsStep formData={formData} onChange={handleInputChange} />;
      case "requirements":
        return (
          <RequirementsStep formData={formData} onChange={handleInputChange} />
        );
      case "jobDescription":
        return (
          <JobDescriptionStep
            formData={formData}
            onChange={handleInputChange}
          />
        );
      case "about":
        return <AboutStep formData={formData} onChange={handleInputChange} />;
      case "preview":
        return <PreviewStep formData={formData} />;
      default:
        return null;
    }
  };

  return (
    <div className="max-w-screen-lg mx-auto p-6">
      <Card className="mb-6">
        <div className="p-6">
          {/* Flex container for the title and dropdown */}
          <div className="flex items-center justify-between mb-8">
            <h2 className="text-2xl font-bold text-indigo-800">
              Create Job Post
            </h2>
            <div className="relative flex items-center">
              <Copy size={20} className="text-gray-400 mr-2" />
              <select
                className="pl-2 pr-8 py-2 border-2 border-gray-200 rounded-lg text-gray-700 bg-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                onChange={(e) => handleJobSelection(e.target.value)}
                disabled={isLoadingJobs}
              >
                <option value="">Copy from previous job</option>
                {previousJobs.map((job) => (
                  <option key={job.id} value={job.id}>
                    {job.title} ({job.job_refreneces})
                  </option>
                ))}
              </select>
              {isLoadingJobs && (
                <div className="absolute right-0 top-1/2 transform -translate-y-1/2">
                  <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-indigo-500"></div>
                </div>
              )}
            </div>
          </div>

          {/* Enhanced Stepper */}
          <div className="mb-12 pl-6 pr-10 pb-4">
            <div className="flex items-center justify-between relative">
              {/* Progress Bar */}
              <div className="absolute top-1/2 left-0 h-1 bg-gray-200 transform -translate-y-1/2 transition-all duration-500 ease-in-out w-full">
                <div
                  className="h-full bg-indigo-500 transition-all duration-500 ease-in-out"
                  style={{
                    width: `${(getCurrentStepIndex() / (steps.length - 1)) * 100}%`,
                  }}
                />
              </div>

              {/* Steps */}
              {steps.map((step, index) => {
                const isActive = currentStep === step.id;
                const isCompleted = validationFunctions[step.id]();

                return (
                  <div
                    key={step.id}
                    className="relative z-10 flex flex-col items-center"
                  >
                    <button
                      onClick={() => setCurrentStep(step.id)}
                      className={`
                        w-10 h-10 rounded-full flex items-center justify-center
                        font-semibold text-sm transition-all duration-200
                        ${
                          isCompleted
                            ? "bg-indigo-500 text-white shadow-lg ring-2 ring-indigo-300"
                            : isActive
                              ? "bg-white text-indigo-500 shadow-lg ring-2 ring-indigo-500"
                              : "bg-white text-gray-400 shadow ring-2 ring-gray-200"
                        }
                        cursor-pointer hover:scale-110
                      `}
                    >
                      {isCompleted ? <Check size={20} /> : step.icon}
                    </button>

                    <div className="absolute -bottom-8 whitespace-nowrap">
                      <span
                        className={`text-sm font-medium
                          ${isActive ? "text-indigo-600" : "text-gray-500"}
                        `}
                      >
                        {step.label}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {/* Current Step Content */}
          <div className="mb-8">{renderStep()}</div>

          {/* Navigation Buttons */}
          <div className="flex justify-between">
            <button
              onClick={() => {
                const currentIndex = getCurrentStepIndex();
                if (currentIndex > 0) {
                  setCurrentStep(steps[currentIndex - 1].id);
                }
              }}
              className={`
                px-6 py-2 rounded-lg flex items-center gap-2
                ${
                  currentStep === steps[0].id
                    ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                    : "bg-white text-indigo-500 border-2 border-indigo-500 hover:bg-indigo-50 transition-colors"
                }
              `}
              disabled={currentStep === steps[0].id}
            >
              Previous
            </button>

            {currentStep === "preview" ? (
              <button
                onClick={handleSubmit}
                disabled={isSubmitting}
                className="px-6 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-all duration-200 disabled:bg-green-300 hover:scale-105"
              >
                {isSubmitting ? "Creating Job..." : "Create Job"}
              </button>
            ) : (
              <button
                onClick={() => {
                  const currentIndex = getCurrentStepIndex();
                  const validateCurrentStep = validationFunctions[currentStep];
                  if (validateCurrentStep()) {
                    if (currentIndex < steps.length - 1) {
                      setCurrentStep(steps[currentIndex + 1].id);
                    }
                  } else {
                    toast.error(
                      "Please fill out all required fields before proceeding.",
                    );
                  }
                }}
                className="px-6 py-2 bg-indigo-500 text-white rounded-lg hover:bg-indigo-600 transition-all duration-200 flex items-center gap-2 hover:scale-105"
              >
                Next
                <ChevronRight size={18} />
              </button>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default JobPostForm;
