import React, { useEffect, useState } from "react";
import JobDataService from "../services/JobDataService";

const RequirementsStep = ({ formData, onChange }) => {
  const [qualifications, setQualifications] = useState([]);
  const [skills, setSkills] = useState([]);
  const [additionalRequirements, setAdditionalRequirements] = useState([]);

  useEffect(() => {
    // Load qualifications and skills
    const loadOptions = async () => {
      try {
        const qualificationsData = await JobDataService.getQualifications();
        const skillsData = await JobDataService.getSkills();
        const additional_requirements =
          await JobDataService.additionalRequirements();

        setQualifications(qualificationsData);
        setSkills(skillsData);
        setAdditionalRequirements(additional_requirements);
      } catch (error) {
        console.error("Error loading qualifications and skills:", error);
      }
    };

    loadOptions();
  }, []);

  const safeFormData = {
    ...formData,
    qualifications: Array.isArray(formData.qualifications)
      ? formData.qualifications
      : [],
    preferred_qualifications: Array.isArray(formData.preferred_qualifications)
      ? formData.preferred_qualifications
      : [],
    preferred_skills: Array.isArray(formData.preferred_skills)
      ? formData.preferred_skills
      : [],
    cv_required:
      formData.cv_required !== undefined ? formData.cv_required : true, // Default to true
    is_references_required:
      formData.is_references_required !== undefined
        ? formData.is_references_required
        : true, // Default to true
    references_required_options: formData.references_required_options || "2", // Default to 2 references
    pre_screening_questions: Boolean(formData.pre_screening_questions),
  };

  return (
    <div className="flex flex-col gap-4 max-w-[456px]">
      {/* Qualifications */}
      <div>
        <label className="block text-gray-700 text-sm font-medium mb-1">
          Qualifications <span className="text-red-500 font-bold">*</span>
        </label>
        <p className="text-xs text-gray-500 mb-2">
          Hold down the Ctrl (Windows) or Command (Mac) button to select
          multiple options.
        </p>
        <select
          multiple
          className="w-full px-3 py-2 border border-gray-300 rounded-md h-32"
          value={safeFormData.qualifications}
          onChange={(e) =>
            onChange(
              "qualifications",
              Array.from(e.target.selectedOptions, (option) => option.value),
            )
          }
        >
          {qualifications.map((qualification) => (
            <option key={qualification.id} value={qualification.id}>
              {qualification.name}
            </option>
          ))}
        </select>
      </div>

      {/* Preferred Qualifications */}
      <div>
        <label className="block text-gray-700 text-sm font-medium mb-1">
          Preferred Qualifications
        </label>
        <p className="text-xs text-gray-500 mb-2">
          Hold down the Ctrl (Windows) or Command (Mac) button to select
          multiple options.
        </p>
        <select
          multiple
          className="w-full px-3 py-2 border border-gray-300 rounded-md h-32"
          value={safeFormData.preferred_qualifications}
          onChange={(e) =>
            onChange(
              "preferred_qualifications",
              Array.from(e.target.selectedOptions, (option) => option.value),
            )
          }
        >
          {qualifications.map((qualification) => (
            <option key={qualification.id} value={qualification.id}>
              {qualification.name}
            </option>
          ))}
        </select>
      </div>

      {/* Additional Requirements */}
      <div>
        <label className="block text-gray-700 text-sm font-medium mb-2">
          Additional Requirements
        </label>
        <select
          multiple
          className="w-full px-3 py-2 border border-gray-300 rounded-md h-32"
          value={safeFormData.additional_requirements}
          onChange={(e) =>
            onChange(
              "additional_requirements",
              Array.from(e.target.selectedOptions, (option) => option.value),
            )
          }
        >
          {additionalRequirements.map((req, index) => (
            <option key={index} value={req.id}>
              {req.value}
            </option>
          ))}
        </select>
      </div>

      {/* Preferred Skills */}
      <div>
        <label className="block text-gray-700 text-sm font-medium mb-1">
          Preferred Skills
        </label>
        <p className="text-xs text-gray-500 mb-2">
          Hold down the Ctrl (Windows) or Command (Mac) button to select
          multiple options.
        </p>
        <select
          multiple
          className="w-full px-3 py-2 border border-gray-300 rounded-md h-32"
          value={safeFormData.preferred_skills}
          onChange={(e) =>
            onChange(
              "preferred_skills",
              Array.from(e.target.selectedOptions, (option) => option.value),
            )
          }
        >
          {skills.map((skill) => (
            <option key={skill.id} value={skill.id}>
              {skill.name}
            </option>
          ))}
        </select>
      </div>

      {/* Experience */}
      <div>
        <label className="block text-gray-700 text-sm font-medium mb-2">
          Minimum Years of Experience
        </label>
        <select
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
          value={safeFormData.min_year_exp || ""}
          onChange={(e) => onChange("min_year_exp", e.target.value)}
        >
          <option value="">Select Experience</option>
          <option value="0">No experience required</option>
          <option value="1 year">1 year</option>
          <option value="2 years">2 years</option>
          <option value="3 years">3 years</option>
          <option value="5 years">5+ years</option>
          <option value="10 years">10+ years</option>
        </select>
      </div>

      {/* Education */}
      <div>
        <label className="block text-gray-700 text-sm font-medium mb-2">
          Education
        </label>
        <select
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
          value={safeFormData.education || ""}
          onChange={(e) => onChange("education", e.target.value)}
        >
          <option value="">Select Education Level</option>
          <option value="high-school">High School</option>
          <option value="bachelors">Bachelor's Degree</option>
          <option value="masters">Master's Degree</option>
          <option value="phd">PhD</option>
        </select>
      </div>

      {/* Remote Work */}
      <div>
        <label className="block text-gray-700 text-sm font-medium mb-2">
          Remote Work
        </label>
        <select
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
          value={safeFormData.remoteType || ""}
          onChange={(e) => onChange("remoteType", e.target.value)}
        >
          <option value="">Select Remote Work Option</option>
          <option value="no">No remote work</option>
          <option value="hybrid">Hybrid</option>
          <option value="full">Fully remote</option>
        </select>
      </div>

      {/* CV Required */}
      <div>
        <label className="flex items-center gap-2">
          <input
            type="checkbox"
            checked={safeFormData.cv_required}
            onChange={(e) => onChange("cv_required", e.target.checked)}
            className="rounded"
          />
          <span className="text-sm">CV Required</span>
        </label>
      </div>

      {/* References */}
      <div>
        <label className="flex items-center gap-2 mb-2">
          <input
            type="checkbox"
            checked={safeFormData.is_references_required}
            onChange={(e) =>
              onChange("is_references_required", e.target.checked)
            }
            className="rounded"
          />
          <span className="text-sm">References Required</span>
        </label>

        {safeFormData.is_references_required && (
          <select
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
            value={safeFormData.references_required_options}
            onChange={(e) =>
              onChange("references_required_options", e.target.value)
            }
          >
            <option value="1">1 Reference</option>
            <option value="2">2 References</option>
            <option value="3">3 References</option>
          </select>
        )}
      </div>

      {/* Pre-screening Questions */}
      {/* 
      <div>
        <label className="flex items-center gap-2">
          <input
            type="checkbox"
            checked={safeFormData.pre_screening_questions}
            onChange={(e) => onChange('pre_screening_questions', e.target.checked)}
            className="rounded"
          />
          <span className="text-sm">Include Pre-screening Questions</span> 
        </label>
      </div>
      */}
    </div>
  );
};

export default RequirementsStep;
