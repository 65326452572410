import React, { useState, useRef, useEffect, useCallback } from "react";
import { Send, Mic, SkipForward, Upload, X } from "lucide-react";
import defaultQuestions from "./defaultQuestions.json";
import defaultOnBoardingQuestions from "./defaultOnBoardingQuestions.json";
import defaultJobSeekerObBoardingQuestions from "./defaultJobSeekerObBoardingQuestions.json";
import {
  saveResponse,
  createConversation,
  endConversation,
  assessmentQuestionnaire,
  checkForToken,
} from "./utils/chat";

import { uploadFiles } from "./utils";

// Mock API function to load questions
const fetchQuestionsFromAPI = async (jobId) => {
  // TODO: Replace this with a real API call in the future
  await new Promise((resolve) => setTimeout(resolve, 500)); // Simulate API delay
  return defaultQuestions.filter((q) => q.jobId === jobId);
};

const fetchOnBoardingQuestionsFromAPI = async (usertype) => {
  // TODO: Replace this with a real API call in the future
  const searchParams = new URLSearchParams(window.location.search);
  const job = searchParams.get("job");
  const jobseeker = searchParams.get("j");
  await new Promise((resolve) => setTimeout(resolve, 500)); // Simulate API delay

  const user = usertype === "c" ? "employer" : "jobseeker";
  let questions = [];

  if (usertype === "c") {
    questions = defaultOnBoardingQuestions.onboardingQuestions[user];
  } else if (usertype === "j") {
    questions = defaultJobSeekerObBoardingQuestions.onboardingQuestions[user]; // when it's a registation request
  } else if (usertype === "a") {
    // when it's a new request for assessemtn
    questions = await assessmentQuestionnaire(job, jobseeker);
  }
  return questions;
};

const ChatInterface = ({ selectedJob, onJobChange, jobs, userType }) => {
  const [messages, setMessages] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [userAnswer, setUserAnswer] = useState("");
  const [questionQueue, setQuestionQueue] = useState([]);
  const [answeredQuestions, setAnsweredQuestions] = useState({});
  const [skippedQuestions, setSkippedQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isRecording, setIsRecording] = useState(false);
  const [conversation, setConversation] = useState(null);
  const [chatAttachment, setChatAttachment] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const messagesEndRef = useRef(null);
  const textAreaRef = useRef(null);
  const sendButtonRef = useRef(null);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [timeTaken, setTimeTaken] = useState(new Date()); // time taken to perform assessment in millisec
  const loadQuestions = useCallback(async () => {
    setIsLoading(true);
    try {
      let questions;
      if (!selectedJob) {
        questions = await fetchOnBoardingQuestionsFromAPI(userType);
      } else {
        questions = await fetchQuestionsFromAPI(selectedJob);
      }

      setQuestionQueue(questions);
      setAnsweredQuestions({});
      setSkippedQuestions([]);
      let defaultMessage = {
        text: "Welcome! Let's begin the interview.",
        sender: "bot",
      };
      if (userType === "j") {
        defaultMessage = {
          text: "Welcome! Let's begin with the Registration process.",
          sender: "bot",
        };
      }
      setMessages([defaultMessage]);
      setNextQuestion(questions);
    } catch (error) {
      console.error("Failed to fetch questions:", error);
      setMessages([
        {
          text: "Sorry, we couldn't load the questions. Please try again later.",
          sender: "bot",
        },
      ]);
    } finally {
      setIsLoading(false);
    }
  }, [selectedJob, userType]);

  useEffect(() => {
    checkForToken("assessment").then((valid) => {
      if (valid) {
        loadQuestions();
        setIsTokenValid(true);
      } else {
        let defaultMessage = {
          text: "Link is Expired or Used",
          sender: "bot",
        };
        setIsTokenValid(false);
        setMessages([defaultMessage]);
        setNextQuestion([]);
        setIsLoading(false);
      }
    });
  }, [selectedJob, loadQuestions]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const newConversation = async () => {
      let user_type = "company_user";
      if (userType === "j") {
        user_type = "jobseeker";
      }
      const c = await createConversation(user_type);
      setConversation(c.data._id);
    };
    newConversation();
  }, []);

  const focusOnInput = useCallback(() => {
    if (
      currentQuestion?.answer_type === "Open-ended" ||
      currentQuestion?.answer_type === "text" ||
      currentQuestion?.answer_type === "email" ||
      currentQuestion?.answer_type === "tel"
    ) {
      textAreaRef.current?.focus();
    } else if (userAnswer && currentQuestion?.answer_type !== "Open-ended") {
      sendButtonRef.current?.focus();
    }
  }, [currentQuestion, userAnswer]);

  useEffect(() => {
    focusOnInput();
  }, [currentQuestion, focusOnInput]);

  const logQuestionStates = (questions) => {
    //console.log("Current state of questions:");
    questions.forEach((q) => {
      /*
    console.log(
      `Question ID: ${q.id}, Text: ${q.question_text}, Answered: ${answeredQuestions[q.id]}, Skipped: ${skippedQuestions.includes(q.id)}`,
    );
    */
    });
  };

  const setNextQuestion = (questions) => {
    logQuestionStates(questions);

    // First, look for unanswered questions that haven't been skipped
    const nextUnansweredQuestion = questions.find(
      (q) => !answeredQuestions[q.id] && !skippedQuestions.includes(q.id),
    );

    if (nextUnansweredQuestion) {
      setCurrentQuestion(nextUnansweredQuestion);
      setMessages((prev) => [
        ...prev,
        {
          text: nextUnansweredQuestion.question_text,
          sender: "bot",
          isQuestion: true,
          required: nextUnansweredQuestion.required,
        },
      ]);

      setChatAttachment(false); //default value
      if (nextUnansweredQuestion.type === "file") {
        setChatAttachment(true);
      }
    } else if (skippedQuestions.length > 0) {
      // If all unanswered questions are done, move to skipped questions
      handleSkippedQuestions(questions);
    } else {
      // If no more questions (unanswered or skipped), end the questionnaire
      endQuestionnaire();
    }
  };

  const handleSkippedQuestions = (questions) => {
    if (skippedQuestions.length > 0) {
      setMessages((prev) => [
        ...prev,
        {
          text: "Great! Now let's go through the questions you skipped earlier.",
          sender: "bot",
        },
      ]);

      // Find the first skipped question
      const nextSkippedQuestion = questions.find((q) =>
        skippedQuestions.includes(q.id),
      );

      if (nextSkippedQuestion) {
        setCurrentQuestion(nextSkippedQuestion);
        setMessages((prev) => [
          ...prev,
          {
            text: nextSkippedQuestion.question_text,
            sender: "bot",
            isQuestion: true,
          },
        ]);
        // Remove this question from the skipped questions list
        setSkippedQuestions((prev) =>
          prev.filter((id) => id !== nextSkippedQuestion.id),
        );

        setChatAttachment(false); //default value
        if (nextSkippedQuestion.type === "file") {
          setChatAttachment(true);
        }
      } else {
        // If we somehow don't find the skipped question, end the questionnaire
        endQuestionnaire();
      }
    } else {
      endQuestionnaire();
    }
  };

  const handleSendAnswer = async () => {
    if ((!userAnswer.trim() && uploadedFiles.length === 0) || isUploading)
      return;

    setIsUploading(true);

    let fileUrls = [];
    if (uploadedFiles.length > 0) {
      try {
        fileUrls = await uploadFiles(uploadedFiles, "chat");
      } catch (error) {
        console.error("Error uploading files:", error);
        setIsUploading(false);
        return;
      }
    }

    const newMessage = {
      text: userAnswer.trim(),
      sender: "user",
      files: fileUrls.map((file) => ({
        name: file.name,
        file: file.url,
        fileType: file.mimetype,
      })),
      user: {},
    };

    const searchParams = new URLSearchParams(window.location.search);
    const query_jobseeker = searchParams.get("j");

    if (query_jobseeker) {
      // Now set the 'userId'
      newMessage["user"]["userId"] = query_jobseeker;
    }

    setMessages((prev) => [...prev, newMessage]);
    setUserAnswer("");
    setUploadedFiles([]);
    setIsUploading(false);

    // Here you would typically send the message and file URLs to your backend
    let question_details = currentQuestion;
    if (currentQuestion.category === "assessment") {
      question_details = currentQuestion.question;
    }
    await saveResponse(
      conversation,
      question_details,
      newMessage,
      currentQuestion.type,
    ); // sent question and answer

    // Proceed with the next question
    const updatedQueue = handleFollowUpQuestions();
    setQuestionQueue(updatedQueue);
    setNextQuestion(updatedQueue);
  };

  const handleSkipQuestion = () => {
    if (!skippedQuestions.includes(currentQuestion.id)) {
      setSkippedQuestions((prev) => [...prev, currentQuestion.id]);
    }
    setCurrentQuestion(null);
    setNextQuestion(questionQueue.filter((q) => q.id !== currentQuestion.id));
  };

  const handleFollowUpQuestions = () => {
    let newQueue = [...questionQueue];
    const followUps = currentQuestion.follow_up_questions || [];
    const matchingFollowUps = followUps.filter((fu) =>
      checkCondition(fu.condition, fu.condition_value, userAnswer),
    );

    if (matchingFollowUps.length > 0) {
      const currentIndex = newQueue.findIndex(
        (q) => q.id === currentQuestion.id,
      );
      newQueue.splice(
        currentIndex + 1,
        0,
        ...matchingFollowUps.map((fu) => fu.question),
      );
    }

    return newQueue.filter((q) => q.id !== currentQuestion.id);
  };

  const endQuestionnaire = () => {
    let endMessage = "";
    if (userType === "a") {
      endMessage = {
        text: "Thank you for completing the questionnaire. Your responses will be reviewed shortly.",
        sender: "bot",
      };
    } else {
      // when it's jobseeker
      endMessage = {
        text: "Thank you for completing the registration. We will send you an email shortly with further details.",
        sender: "bot",
      };
    }

    if (isTokenValid) {
      setMessages((prev) => [...prev, endMessage]);
      setCurrentQuestion(null);
      setQuestionQueue([]);
    }

    let endMode = "chat";
    const total_time = new Date() - timeTaken;
    setTimeTaken(total_time);
    if (userType === "j") {
      endMode = "jobseeker_registation";
      endConversation(conversation, endMode, total_time);
      console.log("Sending questionnaire data to API...");
    } else if (userType === "a") { // when usertype is of assessment
      endMode = "assessment";
      endConversation(conversation, endMode, total_time);
      console.log("Sending questionnaire data to for Assessment API...");
    }
  };

  // Added missing functions
  const checkCondition = (condition, conditionValue, userAnswer) => {
    switch (condition) {
      case "yes_no":
        return userAnswer.toLowerCase() === conditionValue.toLowerCase();
      // Add more condition types here as needed
      default:
        return false;
    }
  };

  const handleTextAreaChange = (e) => {
    setUserAnswer(e.target.value);
    e.target.style.height = "auto";
    e.target.style.height = e.target.scrollHeight + "px";
  };

  const handleQuickActionClick = (answer) => {
    setUserAnswer(answer);
    setTimeout(() => sendButtonRef.current?.focus(), 0);
  };

  const handleKeyPress = (e) => {
    if (
      e.key === "Enter" &&
      !e.shiftKey &&
      document.activeElement === sendButtonRef.current
    ) {
      e.preventDefault();
      handleSendAnswer();
    }
  };

  const startRecording = () => {
    setIsRecording(true);
    // TODO: Implement actual recording start
    console.log("Started recording");
  };

  const stopRecording = () => {
    setIsRecording(false);
    // TODO: Implement actual recording stop
    console.log("Stopped recording");
    processRecording();
  };

  const processRecording = () => {
    // TODO: Implement actual speech-to-text processing
    console.log("Processing recording");
    // Simulating transcription result
    const dummyTranscription =
      "This is a dummy transcription of the user's speech.";
    setUserAnswer(dummyTranscription);
  };

  const handleMicClick = () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  const renderQuestionInput = () => {
    if (!currentQuestion) return null;

    const commonButtonClasses =
      "h-14 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500";

    switch (currentQuestion.answer_type) {
      case "text":
      case "tel":
      case "email":
      case "Open-ended":
        return (
          <div className="flex relative w-full">
            <div className="flex-auto w-10/12">
              <textarea
                ref={textAreaRef}
                value={userAnswer}
                onChange={handleTextAreaChange}
                className="w-full p-3 pr-12 border rounded-md min-h-[56px] resize-none overflow-hidden"
                placeholder="Type your answer here..."
              />
            </div>
            <div className="text-center flex-auto w-1/6">
              <div className="grid grid-cols-2">
                <div className="inline-block">
                  <button
                    onClick={handleMicClick}
                    className={`w-1/2 relative right-2 top-1/2 p-2 rounded-full ${isRecording
                      ? "text-red-500 animate-pulse"
                      : "text-gray-500"
                      }`}
                  >
                    <Mic size={24} />
                  </button>
                </div>
                <div className="">
                  {chatAttachment ? (
                    <button
                      className={`relative right-2 top-1/2 p-2 rounded-full text-gray-500`}
                    >
                      <Upload size={24} />
                      <input
                        type="file"
                        name="chat_attachment"
                        className="absolute inset-0 opacity-0 cursor-pointer"
                        onChange={handleUploadAttachments}
                        multiple
                      />
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      case "Multiple Choice":
        return (
          <div className="flex flex-wrap gap-2 w-full">
            {currentQuestion.options.map((option, index) => (
              <button
                key={index}
                onClick={() => handleQuickActionClick(option)}
                className={`${commonButtonClasses} ${userAnswer === option
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-gray-700"
                  }`}
              >
                {option}
              </button>
            ))}
          </div>
        );
      case "Yes/No":
        return (
          <div className="flex gap-2 w-full">
            <button
              onClick={() => handleQuickActionClick("Yes")}
              className={`${commonButtonClasses} flex-1 ${userAnswer === "Yes"
                ? "bg-blue-500 text-white"
                : "bg-gray-200 text-gray-700"
                }`}
            >
              Yes
            </button>
            <button
              onClick={() => handleQuickActionClick("No")}
              className={`${commonButtonClasses} flex-1 ${userAnswer === "No"
                ? "bg-blue-500 text-white"
                : "bg-gray-200 text-gray-700"
                }`}
            >
              No
            </button>
          </div>
        );
      case "Rating":
        return (
          <div className="flex gap-2 w-full">
            {[1, 2, 3, 4, 5].map((rating) => (
              <button
                key={rating}
                onClick={() => handleQuickActionClick(rating.toString())}
                className={`${commonButtonClasses} flex-1 ${userAnswer === rating.toString()
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-gray-700"
                  }`}
              >
                {rating}
              </button>
            ))}
          </div>
        );
      case "File":
        return (
          <input
            type="file"
            onChange={(e) => handleQuickActionClick(e.target.files[0].name)}
            className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 h-14"
            accept={currentQuestion.allowedFileTypes}
          />
        );
      default:
        return null;
    }
  };

  const renderQuestionWithSkip = (question) => (
    <div className="mb-4 text-left">
      <div className="inline-block p-3 rounded-lg bg-green-100 text-gray-700 border border-green-300 max-w-[85%]">
        {question.text}
      </div>
      <div className="mt-2 text-right">
        <button
          onClick={handleSkipQuestion}
          className="inline-flex items-center px-3 py-2 border border-blue-300 text-sm leading-4 font-medium rounded-md text-blue-700 bg-white hover:text-blue-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-blue-800 active:bg-blue-50 transition ease-in-out duration-150"
        >
          Skip for now
          <SkipForward size={16} className="ml-2" />
        </button>
      </div>
    </div>
  );

  // ... (keep existing functions)

  const handleUploadAttachments = (event) => {
    const files = Array.from(event.target.files);
    setUploadedFiles((prevFiles) => [...prevFiles, ...files]);

    // Add file names to messages
    const fileNames = files.map((file) => file.name).join(", ");
    /*
  setMessages((prev) => [
    ...prev,
    {
      text: `Uploaded files: ${fileNames}`,
      sender: "user",
      isFile: true,
      files: files,
    },
  ]);
  */

    // Clear the file input
    //setUserAnswer(fileNames);
    event.target.value = "";
  };

  const removeFile = (fileToRemove) => {
    setUploadedFiles((prevFiles) =>
      prevFiles.filter((file) => file !== fileToRemove),
    );

    setMessages(
      (prevMessages) =>
        prevMessages
          .map((message) => {
            if (message.isFile && message.files) {
              const updatedFiles = message.files.filter(
                (file) => file !== fileToRemove,
              );
              const uploadedFilesName = updatedFiles
                .map((file) => file.name)
                .join(", ");
              //setUserAnswer(uploadedFilesName);
              if (updatedFiles.length === 0) {
                // If no files left, remove the message
                return null;
              }
              return {
                ...message,
                files: updatedFiles,
                text: `Uploaded files: ${uploadedFilesName}`,
              };
            }

            return message;
          })
          .filter(Boolean), // Remove null messages
    );
  };

  const renderFilePreview = (file) => {
    const isImage = file.type.startsWith("image/");
    return (
      <div key={file.name} className="relative inline-block m-2">
        {isImage ? (
          <img
            src={URL.createObjectURL(file)}
            alt={file.name}
            className="w-20 h-20 object-cover rounded-md"
          />
        ) : (
          <div className="w-20 h-20 bg-gray-200 flex items-center justify-center rounded-md">
            <span className="text-xs text-center">{file.name}</span>
          </div>
        )}
        <button
          onClick={() => removeFile(file)}
          className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1"
        >
          <X size={12} />
        </button>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="flex-grow flex justify-center items-center">
        Loading questions...
      </div>
    );
  }
  return (
    <div className="flex-grow flex justify-center overflow-hidden lg:p-8">
      <div className="w-full md:max-w-3xl flex flex-col bg-white shadow-lg md:mt-2 md:mb-4 md:rounded-lg">
        <div className="sm:mx-auto sm:w-full sm:max-w-md pb-10">
        <img className="mx-auto h-12 w-auto" src="./whoppit-logo.png" alt="Whoppit" />
        </div>
        <div
          className="flex-grow overflow-y-auto p-4"
          style={{ maxHeight: "calc(100vh - 160px)" }}
        >
          {messages.map((message, index) => (
            <div
              key={index}
              className={`mb-4 ${message.sender === "user" ? "text-right" : "text-left"}`}
            >
              {message.required === false ? (
                renderQuestionWithSkip(message)
              ) : (
                <div
                  className={`inline-block p-3 rounded-lg ${message.sender === "user"
                    ? "bg-blue-500 text-white"
                    : "bg-white text-gray-700 border border-gray-300"
                    } max-w-[85%]`}
                >
                  {message.text}
                  {message.files && message.files.length > 0 && (
                    <div className="mt-2 flex flex-wrap">
                      {message.files.map((file, i) => (
                        <div key={i} className="m-1">
                          {file.name}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>
        <div className="border-t border-gray-200 p-4">
          {currentQuestion && (
            <>
              <div className="mb-2">
                {uploadedFiles.length > 0 && (
                  <div className="flex flex-wrap mb-2">
                    {uploadedFiles.map((file) => renderFilePreview(file))}
                  </div>
                )}
              </div>
              <div className="flex items-start items-center">
                <div className="flex-grow mr-2 w-full">
                  {renderQuestionInput()}
                </div>
                <div className="flex">
                  <button
                    ref={sendButtonRef}
                    onClick={handleSendAnswer}
                    disabled={
                      (!userAnswer.trim() && uploadedFiles.length === 0) ||
                      isUploading
                    }
                    className={`bg-blue-500 text-white h-14 w-14 flex items-center justify-center rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 ${(!userAnswer.trim() && uploadedFiles.length === 0) ||
                      isUploading
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                      }`}
                  >
                    {isUploading ? (
                      <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-white"></div>
                    ) : (
                      <Send size={24} />
                    )}
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatInterface;
